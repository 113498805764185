import { Tabs } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
// import 'antd/dist/antd.css';
import FooterMenu from '../components/landing-page/FooterMenu';
import HeaderMenu from '../components/landing-page/HeaderMenu';
import Addons from '../components/pricing/Addons';
import PricingCardSection from '../components/pricing/PricingCardSection';
import PricingCardSectionYearly from '../components/pricing/PricingCardSectionYearly';

import { useLanguage } from '../context/LanguageContext';
import translations from '../context/Translations';

const { TabPane } = Tabs;

function PricingScreen() {

  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <Helmet>
        <title>Pricing | Aceva GPT</title>
      </Helmet>
      <HeaderMenu t={t} />

      <section className="pricing-section">
        <div className="landing-container pricing-container">
          <div className="landing-title">
            <h1>{t?.pricing?.title}</h1>
            <p>{t?.pricing?.subTitle}</p>
          </div>

          <Tabs defaultActiveKey="1" className="toggle-menu">
            <TabPane className="pricing-plan-type" tab={t?.pricing?.monthlyBilling} key="1">
              {/* monthly billing */}
              <PricingCardSection t={t} />
            </TabPane>
            <TabPane className="pricing-plan-type" tab={t?.pricing?.yearlyBilling} key="2">
              {/* yearly billing */}
              <PricingCardSectionYearly t={t} />
            </TabPane>
          </Tabs>
        </div>

        <Addons />
      </section>

      <FooterMenu t={t} />
    </>
  )
}

export default PricingScreen;
