
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import ConfigBot from '../../components/ConfigBot';

import Accordion from '../../components/Accordion';
import ApearanceLogo from '../../components/widget/ApearanceLogo';
import toast from 'react-hot-toast';

function ApearanceStep({config, setConfig}) {
 
  const [uploadLoading, setUploadLoading] = useState(false)
  const [loading, setLoadLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLinkIds, setGetLinkIds] = useState([])
  const [botId, setBotId] = useState('')
  const [wfile, setWfile] = useState(null)

  const navigate = useNavigate(); 

  const tempImg = "https://res.cloudinary.com/dsvt1ftd0/image/upload/v1702616802/1_gth7c6.png";

  // useEffect(() => {
  //   // Reset the scroll position to the top when the component mounts
  //   window.scrollTo(0, 0);
  // }, []);
  
  // file upload handler
  const uploadAvatarHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "tpy8hir8")
    formData.append("cloud_name", "dsvt1ftd0")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dsvt1ftd0/image/upload', formData, headers)
      setConfig({ ...config, botAvatar: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData1 = new FormData();
    formData1.append('image', file);
    await axios.put(`https://api.gupshup.io/wa/app/${config.waAppId}/business/profile/photo`, formData1, {
      headers: {
        'Accept': 'application/json',
        'apikey': 'jhpk2jjsgzxrtuecpfithompwis66jzo',
        "content-type": 'multipart/form-data'
        // ...formData.getHeaders()
      }
    }).then(async response => {
      console.log('Response:', response.data);

      const formData = new FormData()
      formData.append('file', file)
      formData.append("upload_preset", "tpy8hir8")
      formData.append("cloud_name", "dsvt1ftd0")
  
      try {
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
  
        // const { data } = await axios.post(`${fileUrl}`, formData, headers)
        setUploadLoading(true)
        const {data} = await axios.post('https://api.cloudinary.com/v1_1/dsvt1ftd0/image/upload', formData, headers)
        setConfig({ ...config, logo: data?.url});
        setUploadLoading(false)
      } catch (error) {
        console.error(error)
      }

    }).catch(error => {
      console.error('Error:', error.response.data);
      toast.error(`${error.response.data.error?.message || 'The WhatsApp App Id provided may not be accurate. Please double-check and try again.'}. Display picture should be of min 192 px and max size 5 MB`)
      e.target.value = null
      return 0
    });
  }

  // config handler
  const botConfigHandler = async () => {
    try {
      const {data} = await axios.post(`${apiUrl}/botConfig/`)
    } catch (error) {
      console.log('Error:', error?.message || error?.response?.data)
    }
  }

  return (
    <>
      <div className="create-chatbot-container">
        
        <div className="create-chatbot-file-wrapper">
          <div className="create-chatbot-file-body overflow-y-scroll">
            <Accordion
              classContent="ap-content" 
              classItem="ap-item" 
              classWrapper="ap-wrapper"
              classTitle="ap-title"
              title="Name and Welcome Message"
            >
              <form>
                {/* chatbot name */}
                <div className="form-group">
                  <label htmlFor="">Chatbot name <span className="req">*</span> </label>
                  <input 
                    onChange={(e) => {
                      config.botName = e.target.value
                      setConfig({...config})
                    }} 
                    value={config?.botName}
                    type="text" 
                    placeholder='Chatbot name' 
                  />
                </div>
                {/* welcome message */}
                <div className="form-group">
                  <label htmlFor="">Welcome message <span className="req">*</span></label>
                  <textarea
                    onChange={(e) => {
                      config.welcomeMessage = e.target.value
                      setConfig({...config})
                    }} 
                    value={config?.welcomeMessage}
                  placeholder='Hi, How can I help you?' />
                </div>
              </form>
            </Accordion>
            <Accordion 
              title="Logo"
              classContent="ap-content" 
              classItem="ap-item" 
              classWrapper="ap-wrapper"
              classTitle="ap-title"
            >
              <ApearanceLogo config={config} setConfig={setConfig} uploadFileHandler={uploadFileHandler}/>
              <small style={{textAlign:'left', fontWeight:'bold', marginLeft:'10px'}}>Display picture should be of min 192 px and max size 5 MB</small> 
            </Accordion>
            
          </div>
          {/* chatbot selected item preview */}
          <div className="create-chatbot-preview-wrapper">
            <ConfigBot config={config} setConfig={setConfig} />
          </div>
        </div>
      </div> 
    </>
  )
}

export default ApearanceStep