import axios from 'axios';
import React, { useState } from 'react';
import { apiUrl } from '../../Variables';
import { useGoogleLogin } from '@react-oauth/google';

function LoginWithGoogleCalendar({toggleRefresh}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log('codeResponse', codeResponse)
      const { code } = codeResponse
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      axios.post(`${apiUrl}/gCalender/auth/google/url`, {code}, {headers}).then(res => {
        console.log(res)
        const updatedUser = {...JSON.parse(localStorage.getItem('userInfo')), ...res.data}
        localStorage.setItem('userInfo', JSON.stringify(updatedUser));
        toggleRefresh()
      }).catch(errr => {
        console.log(errr)
      })
    },
    onError: (error) => console.log('Login Failed:', error),
    flow: 'auth-code',
    accessType: 'offline',
    scope: 'openid email profile https://www.googleapis.com/auth/calendar'
  });


  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <button className='btn marketplace-btn' onClick={googleLogin}> 
        <span> Login with Google</span>
      </button>
    </div>
  );
}

export default LoginWithGoogleCalendar;
