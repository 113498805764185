import { Drawer } from 'antd';
import moment from 'moment';
import React from 'react';
import { IoTimeOutline } from 'react-icons/io5';
import { MdOutlineChevronRight, MdOutlineLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
import GoogleCalendarLogo from '../../images/logos/calendar.webp';

const CalendarDetailSidebar = ({ event, onClose }) => {
  const formatEventDate = (date) => {
    return moment(date).format('D, MMMM YYYY [at] h:mmA');
  };

  return (
    <>
      <Drawer title="Event Details" onClose={onClose} visible={event !== null} width={600}>
        {event && (
          <>
            <div className="calendar-meeting-details-wrap">
                <p className='meeting-location'>
                  <MdOutlineLocationOn />
                  <span>{event.location}</span>
                </p>
              <h3>{event.title}</h3>
              <div className="calendar-meeting-details-content">
                <div
                  className='calendar-meeting-details'
                  dangerouslySetInnerHTML={{__html: event.description}}
                />
                
                <div className="calendar-meeting-details-dates">
                  <p> 
                    <IoTimeOutline />
                    <span>{formatEventDate(event.start)}</span>
                  </p>
                  <p>
                    <MdOutlineChevronRight />
                  </p>
                  <p>  
                    {formatEventDate(event.end)}
                  </p>
                </div>
              </div>
              {event?.link && (
                <Link to="#" onClick={() => window.open(event.link, '_blank')}>
                  <button className='btn calendar-meeting-details-btn'>
                    <img className='img-fluid' src={GoogleCalendarLogo} alt="calendar logo" />
                    <span>Open Meeting</span>
                  </button>
                </Link>
              )}
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default CalendarDetailSidebar;
