import React from 'react';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { FiTrash } from "react-icons/fi";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TrainFiles from '../../components/steps/TrainFiles';
import TrainLinks from '../../components/steps/TrainLinks';
import logo from '../../images/error-img.png';


function TrainStep({selectedItems, setSelectedItems}) {

  const handleItemSelection = (itemName) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter(item => item !== itemName));
      localStorage.setItem('selectedLinks', JSON.stringify(selectedItems.filter(item => item !== itemName)))
    } else {
      // if (selectedItems?.length < 6) {
      setSelectedItems([...selectedItems, itemName]);
      localStorage.setItem('selectedLinks', JSON.stringify([...selectedItems, itemName]))
      // } else {
      //   toast.error('You can only select up to 5 items.');
      // }
    }
  };

  return (
    <>
      <div className="create-chatbot-container">
        <div className="create-chatbot-file-wrapper">
          <div className={selectedItems?.length > 0 ? "create-chatbot-file-body" : "create-chatbot-file-body"}>
            <div className="create-title">
              <h2>Data source</h2>
            </div>

            <Tabs>
              <TabList className="create-chatbot-steps">
                <Tab className="create-chatbot-step-item">Links</Tab>
                <Tab className="create-chatbot-step-item">Files</Tab>
              </TabList>

              <TabPanel className="create-chatbot-content">
                <TrainLinks selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
              </TabPanel>

              <TabPanel className="create-chatbot-content">
                <TrainFiles selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>
              </TabPanel>
              
            </Tabs>
          </div>
          {/* chatbot selected item preview */}
          {
           selectedItems?.length > 0 ? (
          <div className="create-chatbot-preview-wrapper upload-cb-wrapper">
            <div className="create-title">
              <h2>Added Links and Files ({selectedItems?.length})</h2>
            </div>
            <div className={selectedItems?.length > 6 ? "uploaded-file-wrap custom-scrollbar added-link-item" : "uploaded-file-wrap custom-scrollbar scrollbar-hide"}>
                {selectedItems?.map((item, index) => (
                  <div key={index} className="uploaded-file-item">
                        <div className="uploaded-file-icon-and-name fetch-link-item">
                          {/* <BiLinkAlt /> */}
                          <span>{item.upFileName || item}</span>
                        </div>

                        <div onClick={() => handleItemSelection(item)} className="uploaded-file-action">
                          {selectedItems.includes(item) && <FiTrash className="selected-icon delete-icon" />}
                          {!selectedItems.includes(item) && <BsPlusSquareDotted />}
                        </div>
                    </div>
                  ))}
                   {/* <p className='file-text-preview'>{selectedItems[0].contentOfUp}</p>  */}
            </div>
            </div>
              ) : (
                <div className="not-link-found">
                  <div className="create-title">
                    <h2>Added Links and Files</h2>
                  </div>
                  <div className="not-found-image">
                    <img className='error-img' src={logo} alt="error image" />
                    <span>Beep.. beep.. no data found.</span>
                  </div>
                </div>
              )
            }
            
        </div>
      </div>
    </>
  )
}

export default TrainStep