import axios from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsX } from 'react-icons/bs';
import { apiUrl } from '../../Variables';
import Layout from '../../components/Layout';
import CalendlyBot from '../../components/marketplace/CalendlyBot';
import CalendlyOAuth from '../../components/marketplace/CalendlyOAuth';
import MarketplaceBreadcrumb from '../../components/marketplace/MarketplaceBreadcrumb';
import mail from '../../images/icons/mail.png';
import calendlyLogo from '../../images/logos/Calendly-Logo.png';

function CalendlyDetailsScreen() {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState('');

  const handleContactClick = () => {
    // Your logic for displaying the popup
    setShowPopup(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Clear email error when user starts typing
    setEmailError('');
  };

  const handleNotify = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // Your logic for email validation
    const isValid = /\S+@\S+\.\S+/.test(email);

    if (!isValid) {
      setEmailError('Please enter a valid calendly profile url.');
    } else {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        }
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };
  
        await axios.post(`${apiUrl}/user/marketPlaceSendNotifyMail`, {email}, {headers}).then(res => {
          console.log(res)
          setEmail('')
          setEmailError('');
          setIsValidEmail(true);
          setShowPopup(false);
        }).catch(err => {
          setEmailError('Some thing went wrong! We did not reach your email address.');
          console.log(err)
        })
      } catch (error) {
        console.log(error)
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Calendly Integration | ACEVA Dashboard</title>
      </Helmet>

      <Layout>
        <CalendlyBot />
        <div className="marketplace-container">
          <MarketplaceBreadcrumb title="Calendly Integration" />
          <div className="marketplace-title marketplace-details-title">
            <h2>Calendly Integration</h2>
          </div>

          {/* marketplace items */}
          <div className="marketplace-item marketplace-details-item">
            <div className="marketplace-item-thumb">
              <img src={calendlyLogo} alt="calendly icon" />
            </div>
            <p>
              Please navigate to the official Calendly website and proceed to sign in using your credentials. Upon successful authentication, you will be provided with a unique user link. Copy this link and proceed to input it into the designated 'Sign-in Link' field. Once completed, you will have established a successful connection to Calendly.
            </p>
            <div className="">
            {/* <Link to="https://calendly.com/">
              <button className="btn marketplace-btn">
                Sign in calendly
              </button>
            </Link> */}
            {/* <CalendlyApiResponse /> */}
            
            <button className="btn marketplace-btn" onClick={handleContactClick}>
              Sign-In Link
            </button>
            <CalendlyOAuth />
            </div>
          </div>
        </div>

        {showPopup && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap">
              <BsX onClick={() => setShowPopup(!showPopup)} className='cancel-icon' />
              <h3>Please enter your profile link</h3>
              <div className="form-group">
                <input type="email" value={email} placeholder='https://calendly.com/borhan365' onChange={handleEmailChange} />
                {emailError && <p className="error-message">{emailError}</p>}
              </div>
              <button className='btn marketplace-btn' onClick={handleNotify}>Submit</button>
            </div>
          </div>
        )}


        {isValidEmail && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap">
              <BsX onClick={() => setIsValidEmail(false)} className='cancel-icon' />
              <div className="marketplace-item-thumb">
                <img src={mail} alt="marketplace icon" />
              </div>
              <h3>Successful, dont forget to check your email.</h3>
              <p>Our representative will contact you via email within 24 hours regarding the integration.</p>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}

export default CalendlyDetailsScreen;
