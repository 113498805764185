import React from 'react'
import { Link } from 'react-router-dom'
import RelatedPostSection from '../../components/blog/RelatedPostSection'
import FooterMenu from '../../components/landing-page/FooterMenu'
import HeaderMenu from '../../components/landing-page/HeaderMenu'
import sidebar from '../../images/bg/aceva.png'

function BlogDetailScreen() {
  return (
    <>
      <HeaderMenu />
        <main className='single-main-container'>
          <div className="container">
            <div className="single-container">
              <div className="single-wrapper">
                <div className="single-cat-badge">

                <Link className='single category-badge' to="/category">Chatbot</Link>
                </div>
                <div className="breadcrumb">
                  <li><Link to="/">Home</Link></li>
                  <li>/</li>
                  <li><Link to="/blog">Blog</Link></li>
                  <li>/</li>
                  <li>10 Simple Steps to Create a Chatbot For Your Website</li>
                </div>
                {/* title and thumb */}
                <div className="single-title">
                  <h1>10 Simple Steps to Create a Chatbot For Your Website</h1>
                  <div className="featured-excerpt latest-excerpt">
                    <Link className='author-badge' to="/author">Suja Krishnan</Link>
                    <span>Date: 29/12/2024</span>
                  </div>
                  <div className="single-thumb">
                    <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/660ac79df91396507f32641e_ConversationalAIforEcommerce.webp" alt="blog thumbnail" />
                  </div>
                </div>
                {/* single content */}
                  <div className="single-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Blandit turpis cursus in hac habitasse platea dictumst quisque sagittis. Eu feugiat pretium nibh ipsum consequat. Integer quis auctor elit sed vulputate. Bibendum ut tristique et egestas quis. Cursus vitae congue mauris rhoncus. Id leo in vitae turpis massa sed elementum. Duis convallis convallis tellus id interdum. Iaculis at erat pellentesque adipiscing commodo. Diam in arcu cursus euismod quis. Habitasse platea dictumst quisque sagittis purus sit amet. Urna cursus eget nunc scelerisque viverra mauris in aliquam sem. Facilisis magna etiam tempor orci eu lobortis elementum nibh tellus. Scelerisque viverra mauris in aliquam sem. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin.</p>

                    <p>Eget lorem dolor sed viverra. Sagittis eu volutpat odio facilisis mauris. Elit duis tristique sollicitudin nibh. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum varius. Elementum facilisis leo vel fringilla est ullamcorper eget nulla facilisi. Eget mauris pharetra et ultrices. Pulvinar sapien et ligula ullamcorper malesuada proin libero nunc consequat. Elementum facilisis leo vel fringilla est ullamcorper. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Orci a scelerisque purus semper eget duis. Ornare massa eget egestas purus.</p>

                    <p>Sit amet tellus cras adipiscing enim eu. Vel risus commodo viverra maecenas accumsan lacus vel facilisis. Odio ut sem nulla pharetra diam. At tellus at urna condimentum mattis pellentesque id nibh tortor. Cursus mattis molestie a iaculis at. Mattis aliquam faucibus purus in massa tempor. Auctor neque vitae tempus quam pellentesque. Auctor augue mauris augue neque gravida in. Auctor elit sed vulputate mi sit amet mauris. Sit amet nisl suscipit adipiscing bibendum est. Quis vel eros donec ac odio. Non enim praesent elementum facilisis leo vel fringilla est ullamcorper. Augue neque gravida in fermentum et sollicitudin ac orci. Porta lorem mollis aliquam ut porttitor leo a diam sollicitudin. Nibh venenatis cras sed felis eget velit aliquet. Lectus mauris ultrices eros in cursus turpis massa tincidunt dui. Eu consequat ac felis donec et. Purus gravida quis blandit turpis cursus.</p>

                    <p>Augue eget arcu dictum varius duis at. Feugiat in fermentum posuere urna nec. Faucibus purus in massa tempor nec. Sed euismod nisi porta lorem mollis aliquam ut porttitor. Nisi quis eleifend quam adipiscing. Diam maecenas sed enim ut sem. Tempor id eu nisl nunc. Imperdiet proin fermentum leo vel. Turpis egestas integer eget aliquet nibh praesent. Ac orci phasellus egestas tellus rutrum tellus. Tellus pellentesque eu tincidunt tortor aliquam nulla. Enim eu turpis egestas pretium aenean pharetra. Aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Nullam vehicula ipsum a arcu cursus vitae congue. Vestibulum morbi blandit cursus risus at ultrices mi. Luctus accumsan tortor posuere ac ut consequat semper. Facilisis leo vel fringilla est ullamcorper eget nulla facilisi.</p>

                    <p>Rhoncus aenean vel elit scelerisque. Metus aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices. Quam quisque id diam vel quam elementum pulvinar etiam non. Orci phasellus egestas tellus rutrum tellus pellentesque eu. Pellentesque adipiscing commodo elit at imperdiet dui accumsan. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Interdum varius sit amet mattis vulputate enim nulla aliquet. At ultrices mi tempus imperdiet nulla malesuada. Sodales neque sodales ut etiam sit amet nisl purus in. Ante metus dictum at tempor commodo ullamcorper a lacus. Tincidunt nunc pulvinar sapien et. Bibendum est ultricies integer quis auctor elit. Accumsan tortor posuere ac ut consequat semper viverra. Feugiat pretium nibh ipsum consequat nisl vel. Aliquet risus feugiat in ante metus dictum at. Tellus in metus vulputate eu.</p>

                  </div>
                  
              </div>
              <div className="single-sidebar">
                <img src={sidebar} alt="sidebar ads" />
              </div>
            </div>
            <RelatedPostSection />
          </div>
        </main>
      <FooterMenu />
    </>
  )
}

export default BlogDetailScreen