import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';

function UpdateUserModel({editUser, setEditUser, editUserInfo, setEditUserInfo, updateSuccess, setUpdateSuccess}) {

  const [user, setUser] = useState({})
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [department, setDepartment] = useState("")
  const [thumb, setThumb] = useState('')
  const [uploading, setUploading] = useState(false)
  

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId')

  const {apiUrl, userInfo} = useAppContext(); 

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post(`http://localhost:5000/api/upload`, formData, config)

      setThumb(data)
      console.log(thumb)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }
  
  const removeUserModel = () => {
    setEditUser(!editUser)
  }

  const updateUserhandle = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        'Accept': 'application/json',
        "Content-Type": 'application/json'
      }
    }
    setUpdateSuccess(false)
    const res = await axios.put(`${apiUrl}/user/${id}`, editUserInfo, config)
    setUpdateSuccess(true)

    setEditUser(!editUser)
  }

  useEffect(() => {

  }, [updateSuccess])

  return (
    <>
      <div className={editUser ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={updateUserhandle} className="create-user-form">

          <div className="form-title">
            <h2>Update user</h2>
            <p>Please change the value to update user.</p>
            <BsX onClick={removeUserModel} className='time-icon' />
          </div>

          {/* name */}
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
            onChange={(e) => setEditUserInfo({...editUserInfo, name: e.target.value})}
            value={editUserInfo?.name} 
            type="text" name="name" id="name"
            />
          </div>

          {/* email */}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input onChange={(e) => setEditUserInfo({...editUserInfo, email: e.target.value})} value={editUserInfo?.email} type="email" id='email' />
          </div>

          {/* phone */}
          <div className="form-group">
            <label htmlFor="email">Phone</label>
            <input onChange={(e) => setEditUserInfo({...editUserInfo, phone: e.target.value})} value={editUserInfo?.phone} type="phone" id='phone' />
          </div>

          <div className="form-row">
            {/* Password */}
            {/* <div className="form-group">
              <label htmlFor="OldPassword">Old Password</label>
              <input type="password" placeholder='Old password' id='OldPassword' />
            </div> */}

            {/* New Password */}
            {/* <div className="form-group">
              <label htmlFor="newPassword">New password</label>
              <input type="password" placeholder='New password' id='newPassword' />
            </div> */}
          </div>

          {/* role and department */}
          <div className="form-row">
            {/* Role */}
            <div className="form-group">
              <label htmlFor="isAdmin">Role ({(editUserInfo?.email === userInfo?.email) && <span className='color-green'>You're current user</span>})</label>
              {
                editUserInfo?.email === userInfo?.email ? (
                  <select id="isAdmin">
                    <option value={editUserInfo?.role}>{editUserInfo?.role}</option>
                  </select>
                ) : (
                  <select onChange={(e) => setEditUserInfo({...editUserInfo, role: e.target.value})} value={editUserInfo?.role} id="isAdmin">
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                    <option value="user">User</option>
                  </select>
                )
              }
            </div>

            {/* Department */}
            <div className="form-group">
              <label htmlFor="department">Department</label>
              <select onChange={(e) => setEditUserInfo({...editUserInfo, department: e.target.value})} value={editUserInfo?.department} id="department">
                <option value="sales">Sales</option>
                <option value="human-resource">Human Resource</option>
                <option value="account">Account</option>
                <option value="finance">Finance</option>
                <option value="audit">Audit</option>
              </select>
            </div>

          </div>
          
          {/* profile pic */}
          {/* <div className="form-group">
            <label>Upload profile picture</label>
            <label>
              <div className="user-profile-header-thumb create-user-profile">
                  <img className='create-user-profile-img' src={thumb ? `${rootUrl}${thumb}` : logo} alt="profile photo" />
                  <div className="user-update-profile create-user-profile-update-icon-wrapper">
                    <input type="file" id='image-file' onChange={(e) => setEditUserInfo({...editUserInfo, profile: e.target.files[0]})} name="profilePic" custom="true" hidden />
                    <MdCloudUpload className='user-update-icon' />
                  </div>
                </div>
              </label>
            </div> */}

          <button type='submit' className='form-button'>
            Update changes
          </button>
        </form>
      </div>
    </>
  )
}

export default UpdateUserModel