import { Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { IoMdSwap } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { apiUrl } from '../../Variables';


function MessengerConnect() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const navigate =  useNavigate()
  const [chatbots, setChatbots] = useState(null);
  const [calendars, setCalendars] = useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedBot, setSelectedBot] = useState(null);
  const [fbPageToken, setFbPageToken] = useState(null);
  const [fbPageId, setFbPageId] = useState(null);
  const onChange = (selected) => {
    console.log(selected)
    setSelectedBot(selected.value)
  };
// fetch group data []
useEffect(() => {
  const fetchGroup = async () => {
    try {

      if (!userInfo || !userInfo.token) {
        console.error('User token not found.');
        return;
      }

      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };

      setLoading(true);

      const res = await axios.get(`${apiUrl}/chatbot`, { headers });
      setChatbots(res.data);

      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Chats fetch error:', error);
    }

    try {
      const gcResponse = await axios.post(`${apiUrl}/gCalender/getCalendars`, {googleRefreshToken: userInfo?.utilityData?.grt});
      // console.log(gcResponse)
      setCalendars(gcResponse.data.calendars)
    } catch (error) {
      console.error('Error fetching Google events:', error);
    }
  };
  fetchGroup();
}, []);

const botOptions = (chatbots || []).map((item) => ({
  value: {
    id: item?._id,
    configData: item?.configData,
    userId: item?.userId
  },
  label: item.name,
}));

const handleConnect = async () => {
   // Add headers to include JWT token
   const headers = {
     Authorization: `Bearer ${userInfo.token}`,
   };
  setLoading(true)
  await axios.get(`${apiUrl}/botConfig/${selectedBot.id}`, {headers}).then(async res => {
    const btc = res.data[0]
    await axios.put(`${apiUrl}/botConfig/${btc?._id}`, {_id: btc?._id, configData: {...btc.configData, fbPageId: fbPageId, fbPageToken: fbPageToken, fbVarifyToken: `${btc.chatbotId}-${btc.configData.botName.replace(/ /g, '-')}`, fbCallbackUrl: `https://gptsaas.aceva.com.my/api/chats/ChatThroughFB/${selectedBot.userId}/${selectedBot.id}`}, chatbotId: btc.chatbotId}, { headers }).then(async res => {
      setLoading(false)
      navigate(`/edit-chatbot/${selectedBot.id}?tab=5`)
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    })
  }).catch((error) => {
    setLoading(false)
    console.log('scrap list fetching fail:', error)
  })
}

  return (
    <div className="gc-container">
      <label htmlFor="">Connect your chatbot with Facebook Messenger</label>
      <div className="gc-connect">
        <div className="gc-item">
          {/* <label htmlFor="">Google Calendars</label> */}
          <Select
            className='gc-google-calendars'
            style={{
              width: '100%',
            }}
            onChange={onChange}
            options={botOptions}
            placeholder="Select chatbot"
            suffixIcon={<BsChevronDown />}
          />
        </div>
        <div className="gc-item">
          <IoMdSwap />
        </div>
        <div className="gc-item">
          {/* <label htmlFor="">Chatbots</label> */}
          <Input
            className='gc-chatbots'
            placeholder="Token..."
            onChange={e => setFbPageToken(e.target.value)}
          />
          <Input
            className='gc-chatbots'
            placeholder="Facebook Page ID..."
            onChange={e => setFbPageId(e.target.value)}
            style={{marginTop: '10px'}}
          />
        </div>
        <div className="gc-item">
          <button className="btn marketplace-btn" onClick={handleConnect}>
            {loading ? 'Loading...' : 'Connect'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MessengerConnect