import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { BiCopy, BiLinkAlt } from 'react-icons/bi';
import { FiChevronRight, FiUpload } from 'react-icons/fi';
import PlainHeader from '../../components/PlainHeader';
import { AiOutlineDelete, AiOutlineLink } from 'react-icons/ai';
import {VscFile} from 'react-icons/vsc'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TrainLinks from '../../components/steps/TrainLinks';
import TrainFiles from '../../components/steps/TrainFiles';
import { apiUrl } from '../../Variables';
import { toast } from 'react-hot-toast';
import Layout from '../../components/Layout';
import { BsCheckCircleFill, BsFillSkipEndFill, BsPlusSquareDotted } from 'react-icons/bs';
import MiniLoading from '../../components/MiniLoading';

function CreateChatbotFileScreen({toggleLoggedInState}) {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const ids = searchParams.get('ids');
  const botId = searchParams.get('botId');

  const [webUrl, setWebUrl] = useState('')  
  const [webUrlLoading, setWebUrlLoading] = useState(false)
  const [links, setLinks] = useState([])
  const [linkSubmitSuccess, setLinkSubmitSuccess] = useState(false)
  const [urlError, setUrlError] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  function isValidURL(url) {
    // Regular expression to match a valid URL format
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?(\?.*)?$/i;
    
    return urlPattern.test(url);
  }
  

  const submitHandler = async (e) => {
    e.preventDefault();

    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    if (!isValidURL(webUrl)) {
      setUrlError('Please enter a valid URL');
      return;
    }

    setUrlError('');

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    try {
      setWebUrlLoading(true)
      const { data } = await axios.post(`${apiUrl}/scrap/getUrls`, {web_url: webUrl}, { headers });
      setWebUrlLoading(false)
      setLinks(data)
      console.log('data', data)
      toast.success('Data scrapped successfully!')
    } catch (error) {
      setWebUrlLoading(false)
      console.error('Error fetching URL:', error);
      toast.error(`Failed: ${error?.response?.data?.message}`)
    }
  };

  const handleItemSelection = (itemName) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter(item => item !== itemName));
    } else {
      if (selectedItems?.length < 6) {
        setSelectedItems([...selectedItems, itemName]);
      } else {
        toast.error('You can only select up to 5 items.');
      }
    }
  };

  // remove query string from the fetched url
  const removeQueryString = (url) => {
    const urlObject = new URL(url);
    urlObject.search = '';
    return urlObject.toString();
  };

  // submit links handler
  const submitLinkHandler = async (e) => {
    e.preventDefault();
    setWebUrlLoading(true)
    // check is selectedItem have value or not!
    if (selectedItems.length === 0) {
      toast.error('Please select at least one URL.');
      return;
    }
    // if (selectedItems.length > 5) {
    //   toast.error('You can only select up to 5 items.');
    //   setWebUrlLoading(true)
    //   return;
    // }
    
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }
  
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    setLinkSubmitSuccess(false);
    await axios.post(`${apiUrl}/scrap/scrapPages`, {page_urls: selectedItems}, { headers }).then(async res => {
      console.log(res.data)
      await axios.get(`${apiUrl}/chatbot/${botId}`, { headers }).then(async res1 => {
        console.log(res1)
        if (res1.data[0].scrappedOrUpDataId.length + selectedItems.length > 5) {
          toast.error('You can only select up to 5 items.');
          setWebUrlLoading(false)
          return;
        }
        await axios.put(`${apiUrl}/chatbot/${botId}`, {...res1.data[0], scrappedOrUpDataId: [...res1.data[0].scrappedOrUpDataId, ...res.data.scrappedIds]}, { headers }).then(res2 => {
          console.log(res2)
          navigate('/chatbot-list');
          setLinkSubmitSuccess(true);
          setLinks(res.data);
          setWebUrlLoading(false)
          toast.success('Scrapped data inserted!');
        }).catch((error) => {
          console.error('Error to bot update:', error);
          setWebUrlLoading(false)
          toast.error(`Failed: ${error?.response?.message || error?.response?.data}`)
        })
      }).catch((error) => {
        console.error('Error to bot update:', error);
        setWebUrlLoading(false)
      })
    }).catch((error) => {
      setWebUrlLoading(false)
      setLinkSubmitSuccess(false);
      console.error('Error:', error);
      toast.error(`Failed: ${error?.response?.data?.message}`);
    })
  }
  

  // isURL function implementation
  function isURL(url) {
    const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return pattern.test(url);
  }
  
  useEffect(() => {
    if(linkSubmitSuccess) {

    }
  },[linkSubmitSuccess])

  console.log('selectedItems', selectedItems)

  // Retrieve userInfo from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [uploadLoading, setUploadLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [text, setText] = useState(null); // State to hold extracted text

  const [fileName, setFileName] = useState('');

  const handleFileUpload = async (event) => {
    setUploadLoading(true)
    const file = event.target.files[0];
    let fileName = ''
    let text = ''

    if (file) {
      setFileName(file.name); // Capture the file name
      console.log(file.name, file.type); // Capture the file name
      fileName = file.name; // Capture the file name

      if (file.type === 'application/pdf') {
       toast.error('Can not read pdf file!')
    
      } else if (file.type === 'text/plain') {

        const reader = new FileReader();

        reader.onload = async (e) => {
          const content = e.target.result;
          setText(content);
          console.log(content);
          text = content;

          // Add headers to include JWT token
          const headers = {
            Authorization: `Bearer ${userInfo.token}`,
          };
          await axios.post(`${apiUrl}/scrap/storePdfData`, {upFileName: fileName, contentOfUp: text}, { headers }).then(async res => {
            console.log(res)
            await axios.get(`${apiUrl}/chatbot/${botId}`, { headers }).then(async res1 => {
              console.log(res)
              await axios.put(`${apiUrl}/chatbot/${botId}`, {...res1.data[0], scrappedOrUpDataId: [...res1.data[0].scrappedOrUpDataId, ...res.data.scrappedIds]}, { headers }).then(res2 => {
                console.log(res2)
                navigate('/chatbot-list');
                setLinkSubmitSuccess(true);
                setLinks(res.data);
                setWebUrlLoading(false)
                toast.success('Scrapped data inserted!');
              }).catch((error) => {
                console.error('Error to bot update:', error);
                setWebUrlLoading(false)
                toast.error(`Failed: ${error?.response?.message || error?.response?.data}`)
              })
            }).catch((error) => {
              console.error('Error to bot update:', error);
              setWebUrlLoading(false)
            })
            navigate('/chatbot-list');
            setUploadLoading(false)
          }).catch(err => {
            toast.error(err.response.data)
            setUploadLoading(false)
          })
        };

        reader.readAsText(file); // For text files, store as text
      }
    }
  };

  return (
    <Layout toggleLoggedInState={toggleLoggedInState}>
      <div className="create-chatbot-container">
        <div className="create-chatbot-file-wrapper">
          <div className="create-title">
            <h2>Data source</h2>
          </div>

          <Tabs>
            <TabList className="create-chatbot-steps">
              <Tab className="create-chatbot-step-item">Links</Tab>
              <Tab className="create-chatbot-step-item">Files</Tab>
            </TabList>

            <TabPanel className="create-chatbot-content">
              <>
                <form onSubmit={submitHandler}>
                  {/* website url */}
                  <div className="embadded-code create-chatbot-input-wrap">
                    <div className="dashboard-header-left embadded-title">
                      <h3 className='title-label'>Website URL</h3>
                    </div>
                    {/* filed */}
                    <div className="embadded-form">
                      <input
                        type="text"
                        placeholder="https://www.example.com/"
                        onChange={(e) => {
                          setWebUrl(e.target.value);
                          setUrlError('');
                        }}
                        className={webUrl === "" || urlError ? "required" : ""}
                      />
                      {urlError && <span className="error-message">{urlError}</span>}
                      <button
                        type="submit"
                        className={`embadded-btn ${webUrl === "" || urlError ? "disabled" : ""}`}
                        disabled={webUrl === "" || urlError}
                      >
                        
                        {
                          webUrlLoading ? (
                            <div className="loading-wrap">
                              <MiniLoading />
                            </div>
                          ) : (
                            <>
                              <AiOutlineLink />
                              <span>Fetch URL</span>
                            </>
                          )
                        }
                      </button>
                    </div>
                  </div>
                </form>
                {/* suggest links */}
                <div className="suggest-links">
                  {links?.length > 0 ? <p className='uploaded-label'>All scrapped links ({links?.length}) </p> : <p className='uploaded-label'></p>}
                  <div className="suggest-links">
                    <div className="uploaded-file-wrap">
                      {links?.map((item, index) => <div key={index} className={`uploaded-file-item ${selectedItems.includes(item) ? 'selected-item' : ''}`}>
                            <div className="uploaded-file-icon-and-name">
                              <BiLinkAlt />
                              <span>{item}</span>
                            </div>
                            <div onClick={() => handleItemSelection(item)} className="uploaded-file-action">
                              {selectedItems.includes(item) && <BsCheckCircleFill className="selected-icon" />}
                              {!selectedItems.includes(item) && <BsPlusSquareDotted />}
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>

                {
                  links.length !== 0 && <div className="chatbot-action-wrap">
                  <li onClick={submitLinkHandler} className='chatbot-action-item active'> { webUrlLoading ? <span>Loading...</span> : <><span>Add</span> <FiChevronRight /></>} </li>
                </div>
                }
              </>
            </TabPanel>

            <TabPanel className="create-chatbot-content">
              <>
                <div className="chatbot-create-content-wrap">
                  {/* ...other JSX code */}
                  <p className='uploaded-label'>Upload new file</p>
                  <div className="chatbot-upload-image-wrap">
                    <label>
                      <div className="chatbot-upload-content-wrap">
                        <FiUpload className='user-update-icon' />
                        <h3>Upload file</h3>
                        <p>Please upload only .txt file</p>
                        <input type="file" accept=".pdf,.txt" onChange={handleFileUpload} id='image-file' name="profilePic" custom="true" hidden />
                        {/* <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden /> */}
                      </div>
                    </label>
                  </div>
                  {
                    uploadLoading && <MiniLoading />
                  }
                  {/* Display extracted text if a file is uploaded */}
                  {text && (
                    <div className="pdf-text-preview">
                      <h3>Extracted Text</h3>
                      <p>{text}</p>
                    </div>
                  )}
                </div>
              </>
            </TabPanel>
            
              
          </Tabs>
        </div>
      </div>
    </Layout>
  )
}

export default CreateChatbotFileScreen