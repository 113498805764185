import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'react-router-dom'

function ChatbotTypeScreen({toggleLoggedInState}) {
  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>
          <section className="chatbot-type-section">
            <div className="container">
              <div className="chatbot-type-wrapper">
                <div className="chatbot-type-content">
                  <h2>Create Chatbot</h2>
                  <p>Which type of chatbot would you like to create?</p>
                </div>
                <div className="chatbot-type-action">
                  <Link to="/create-chatbot-file">
                    <button className="default-button chatbot-type-btn">Website Chatbot</button>  
                  </Link>
                  <Link to="/whatsapp-integration">
                    <button className="default-button chatbot-type-btn whatsapp-btn">WhatsApp Chatbot</button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
      </Layout>
    </>
  )
}

export default ChatbotTypeScreen