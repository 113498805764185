
import { Progress } from 'antd'; // Ensure you have 'antd' installed
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import ConfigBot from '../../components/ConfigBot';

function PublishStep() {

  const [selectedItems, setSelectedItems] = useState(JSON.parse(localStorage.getItem('selectedLinks')) || []);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo'))); 
  const [config, setConfig] = useState({...JSON.parse(localStorage.getItem('botConfig'))})
  const [botInfo, setBotInfo] = useState({...JSON.parse(localStorage.getItem('botInfo'))})
  const [promptLists, setPromptLists] = useState(JSON.parse(localStorage.getItem('menuList')) || []);
  const [uploadLoading, setUploadLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [showPhoneAlert, setShowPhoneAlert] = useState(false)
  const [getLinkIds, setGetLinkIds] = useState([])
  const [botId, setBotId] = useState('')
  const [progress, setProgress] = useState(10); // Add state to manage progress

  const navigate = useNavigate(); 

  // Function to update progress
  const updateProgress = (value) => {
    setProgress(value);
  };

  const finalUpdateBot = async (scrappedIds, headers, createdBot) => {
    try {
      // Update progress to indicate chatbot creation started
      // const chatbotResponse = await axios.post(`${apiUrl}/chatbot`, {...botInfo, scrappedOrUpDataId: scrappedIds}, { headers });
      const chatbotResponse = await axios.put(`${apiUrl}/chatbot/${createdBot._id}`, {...createdBot, scrappedOrUpDataId: scrappedIds}, { headers })
      // Assuming each major step is worth 25% of the progress
      updateProgress(75);

      await axios.post(`${apiUrl}/botConfig`, {configData: config, chatbotId: chatbotResponse?.data?._id}, { headers });
      updateProgress(90);

      const menuData = {
        chatbotId: chatbotResponse.data._id,
        menues: promptLists
      };
  
      await axios.post(`${apiUrl}/botMenu`, menuData, { headers });
      console.log('menu creation response', menuData);
      
      await axios.put(`${apiUrl}/user/${userInfo._id}`, { ...userInfo, whatsappStatus: "integrationDone" }, { headers });
      localStorage.setItem('userInfo', JSON.stringify({...userInfo, whatsappStatus: "integrationDone"}));
  
      updateProgress(100); // Completion
      localStorage.removeItem("botConfig")
      localStorage.removeItem("botInfo")
      localStorage.removeItem("menuList")
      localStorage.removeItem("selectedLinks")
      toast.success('Chatbot created successfully.');
      navigate('/chatbot-list');
    } catch (error) {
      console.error('Submission error', error);
      toast.error("Failed to create the chatbot.");
      setLoading(false);
    }
  };
  const createBot = async (headers) => {
    const chatbotResponse = await axios.post(`${apiUrl}/chatbot`, {...botInfo, scrappedOrUpDataId: []}, { headers });
    return chatbotResponse.data
  }
  const deleteBot = async (id, headers) => {
    await axios.delete(`${apiUrl}/chatbot/${id}`, {headers})
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInfo.phone) {
      setShowPhoneAlert(true);
      return;
    }
    setLoading(true);
    setUploadLoading(true); // Show progress bar
    updateProgress(0); // Reset progress at the start of submission
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    let createdBot = null
    try {
      createdBot = await createBot(headers)
      updateProgress(15);
      const upUrls = []
      let scrappedIds = []
      for (const contents of selectedItems) {
        if (contents?.upFileName) {
            const res = await axios.post(`${apiUrl}/scrap/storePdfData`, {...contents, botId: createdBot._id}, { headers });
            scrappedIds = [...scrappedIds, ...res.data.scrappedIds]
        } else {
            upUrls.push(contents)
        }
      }
      updateProgress(20);
      if (upUrls.length > 0) {
        const res1 = await axios.post(`${apiUrl}/scrap/scrapPages`, {page_urls: upUrls, botId: createdBot._id}, { headers });
        scrappedIds = [...scrappedIds, ...res1.data.scrappedIds]
      }
      updateProgress(50);
      await finalUpdateBot(scrappedIds, headers, createdBot);
    } catch (err) {
      toast.error("We regret to inform you that the website you are attempting to scrape may not be accessible for scraping. This could be due to one of the following reasons:\n \n1. A CAPTCHA or other bot-detection mechanism was detected\n2. The page content could not be parsed successfully\n3. The scraping request limit has been reached\n4. An error occurred while loading the page. \n\nPlease verify the URL or try again later. This resulting in the failure to create the chatbot.");
      if (createBot) {
        deleteBot(createdBot._id, headers)
      }
      setLoading(false);
    }

  };


  return (
    <>
      <div className="create-chatbot-container create-chatbot-final-container">

        {
          showPhoneAlert && <div className="alert-content" style={{margin:'-20px 0 15px 0'}}>
            <p>
              Please update your phone number to continue using your chatbot.
            </p>
            <a href="/settings">Update your phone number</a>
          </div>
        }
          
        <div className="create-chatbot-file-wrapper create-chatbot-final-wrapper">
          {/* chatbot selected item preview */}
          <div className="create-chatbot-final-step">
            <ConfigBot config={config} setConfig={setConfig} promptLists={promptLists} />
          </div>
          
          <div className="create-chatbot-final-content">
            <h2>Everything looks good?</h2>
            <p>Publish your chatbot now. <br /> Don’t worry, you can still edit your chatbot later.</p>

            <div className="progress-wrap">
              {
                loading ? (
                  <Progress percent={progress} />
                ) : (
                  <button onClick={handleSubmit} className="default-button">
                    Publish Your Chatbot
                  </button>
                )
              }
            </div>

            {/* <button onClick={handleSubmit} className="default-button">{loading ? 'Publishing...' : 'Publish Your Chatbot'}</button> */}

          </div>
        </div>
      </div> 
    </>
  )
}

export default PublishStep;