import React from 'react';
// import 'react-tabs/style/react-tabs.css';
import Layout from '../../components/Layout';
import ChatbotSteps from '../../components/antd/ChatbotSteps';

function CreateChatbotFileScreen({toggleLoggedInState}) {

  return (
    <>
      {/* <PlainHeader /> */}
      <Layout toggleLoggedInState={toggleLoggedInState}>
          <div className="chatbot-step-title">
            <h1>Create Chatbot </h1>
            <p>Start your chatbot journey.</p>
          </div>
          <div className="chatbot-steps-wrapper">
            <ChatbotSteps />
          </div>
      </Layout>
    </>
  )
}

export default CreateChatbotFileScreen