import React, { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../images/aceva.png';
import SelectLanguage from './SelectLanguage';

import { useLanguage } from '../../context/LanguageContext';
import translations from '../../context/Translations';

function HeaderMenu() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const location = useLocation();
  const { language } = useLanguage();
  const t = translations[language];

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className={`landing-header-wrapper`}>

        {/* <div className="header-wrap"> */}
          {/* desktop header menu */}
          <div className={`desktop-header-menu-wrapper ${isMenuOpen ? 'menu-open' : ''} ${isSticky ? 'active-sticky' : ''}`}>
            <div className="landing-header-left">
              <ul className="landing-page-menu">
                <li className="header-list-logo">
                  <Link to="/home">
                    <img src={logo} alt="logo" />
                  </Link>
                </li>
                <li className={`menu-list-text-item mobile-hide ${location.pathname === '/how-it-works' ? 'active' : ''}`}>
                  <Link to="/how-it-works">{t?.menu?.howIsItWorks}</Link>
                </li>
                <li className={`menu-list-text-item mobile-hide ${location.pathname === '/live-demo' ? 'active' : ''}`}>
                  <Link to="/live-demo">{t?.menu?.demo}</Link>
                </li>

                <li className={`menu-list-text-item mobile-hide ${location.pathname === '/pricing' ? 'active' : ''}`}>
                  <Link to="/pricing">{t?.menu?.pricing}</Link>
                </li>
              </ul>
            </div>

            <div className="action-header-wrap">
              <div className={`landing-header-right mobile-hide`}>
                <ul className="landing-page-menu landing-right-action-btn-wrap">
                  <li>
                    <Link className="sign-in-btn" to="/login">
                      {t?.menu?.signIn}
                    </Link>
                  </li>
                  <li>
                    <Link to="/register">
                      <button className="landing-hero-action-btn">{t?.menu?.button}</button>
                    </Link>
                  </li>
                  <li className="select-language-item">
                    <SelectLanguage />
                  </li>
                </ul>
              </div>

              <div className="landing-header-toggle" onClick={toggleMenu}>
                {isMenuOpen ? <BsX className='cross' /> : <HiOutlineMenuAlt2 />}
              </div>
            </div>
          </div>

          {/* mobile header menu */}
          <div className={isMenuOpen ? "mobile-header-menu-wrapper active desktop-hide" : "mobile-header-menu-wrapper desktop-hide"}>
            <ul className="landing-page-menu mobile-header-left">
              <li
                className={`menu-list-text-item`}
              >
                <a href="/home#how-it-works">{t?.menu?.howIsItWorks}</a>
              </li>
              <li
                className={`menu-list-text-item`}
              >
                <a href="/home#live-demo">{t?.menu?.demo}</a>
              </li>

              <li
                className={`menu-list-text-item ${location.pathname === '/pricing' ? 'active' : ''}`}
              >
                <Link to="/pricing">{t?.menu?.pricing}</Link>
              </li>

                <ul className="landing-page-menu mobile-header-right">
                <li>
                  <Link className="sign-in-btn" to="/login">
                  {t?.menu?.signIn}
                  </Link>
                </li>
                <li>
                  <Link to="/pricing">
                    <button className="landing-hero-action-btn">{t?.menu?.button}</button>
                  </Link>
                </li>
                <li className="select-language-item">
                  <SelectLanguage />
                </li>
              </ul>
            </ul>
          
            
          </div>
      
      </div>
    </>
  );
}

export default HeaderMenu;
