import { Select } from 'antd';
import React from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { useLanguage } from '../../context/LanguageContext';
import indonesia from '../../images/icons/indonesia.png';
import malaysia from '../../images/icons/malaysia.png';
import usa from '../../images/icons/usa.png';
import { useNavigate } from 'react-router-dom';

const SelectLanguage = () => {

  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate()

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    if (value === 'en') {
      setLanguage('english');
    } else if (value === 'ms') {
      setLanguage('malay');
    } else if (value === "ind") {
      setLanguage('indonesia');
    }
    navigate(`/home/${value}`)
  };

    return (
      <Select
      // defaultValue="english"
      value={language === 'english' ? 'en': language === 'malay' ? 'ms': language === 'indonesia' ? 'ind': ''}
      style={{ width: 95, height: 49 }}
      onChange={handleChange}
      suffixIcon={<IoIosArrowDown />} // This line adds the custom down arrow icon
      options={[
        {
          value: 'en',
          label: (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img className='icon-img' src={usa} alt="English" style={{ marginRight: 10, width: 26 }} />
              En
            </div>
          ),
        },
        {
          value: 'ms',
          label: (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img className='icon-img' src={malaysia} alt="Malay" style={{ marginRight: 10, width: 26 }} />
              My
            </div>
          ),
        },
        {
          value: 'ind',
          label: (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img className='icon-img' src={indonesia} alt="Indonesia" style={{ marginRight: 10, width: 26 }} />
              Ind
            </div>
          ),
        },
      ]}
    />
    )
};

export default SelectLanguage;
