import React, { useState } from 'react';

function Addons() {
  const [extra, setExtra] = useState("1000")
  const [extraWhatsapp, setExtraWhatsapp] = useState("1000")
    // Define a mapping of message credits to prices
    const prices = {
      "1000": 14.90,
      "2000": 29.80,
      "3000": 44.70,
      "4000": 59.60,
      "5000": 74.50,
    };

    const WhatsAppPrices = {
      "1000": 150,
      "2000": 300,
      "3000": 450,
      "4000": 600,
      "5000": 750,
    };
  
    // Function to handle the select box change
    const handleSelectChange = (e) => {
      setExtra(e.target.value);
    };
    
  return (
    <>
      <div className="landing-container addon-container">
        <div className="landing-title">
          <h1>Add-ons</h1>
        </div>

        {/* pricing list */}
        <div className="pricing-wrapper addon-wrapper">

          {/* item - Extra WhatsApp Service Conversation credits */}
          <div className="pricing-item addon-item">
            {/* <div className="addon-badge">Add-ons</div> */}
            <div className="addon-body">
              <div className="pricing-name">Extra WhatsApp credits</div>
              <div className="addon-details">
                
                <p> 150 MYR per 1000 Service Conversations / month</p>
                <p>
                  I want 
                  <select onChange={(e) => setExtraWhatsapp(e.target.value)} className='addon-select' value={extraWhatsapp}>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="3000">3000</option>
                    <option value="4000">4000</option>
                    <option value="5000">5000</option>
                  </select>
                  extra credits every month
                </p>
              </div>
            </div>
            <div className="pricing-action">
            <div className="pricing-price">
              RM <span className='price-amount-badge'>{WhatsAppPrices[extraWhatsapp].toFixed(2)}/mo</span>
            </div>
              {/* <button className='landing-hero-action-btn'>Get Add-ons</button>  */}
            </div>
          </div>

          <div className="pricing-item addon-item">
            {/* <div className="addon-badge">Add-ons</div> */}
            <div className="addon-body">
              <div className="pricing-name">Extra Message credits</div>
              <div className="addon-details">
                <p> 
                  14.90 MYR per 1000 msg credits / month
                </p>
                <p>
                  I want 
                  <select onChange={handleSelectChange} className='addon-select' value={extra}>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="3000">3000</option>
                    <option value="4000">4000</option>
                    <option value="5000">5000</option>
                  </select>
                  extra msg credits every month
                </p>
              </div>
            </div>
            <div className="pricing-action">
            <div className="pricing-price">
              RM <span className='price-amount-badge'>{prices[extra].toFixed(2)}/mo</span>
            </div>
              {/* <button className='landing-hero-action-btn'>Get Add-ons</button>  */}
            </div>
          </div>
          {/* item */}
          <div className="pricing-item addon-item">
            {/* <div className="addon-badge">Add-ons</div> */}
            <div className="addon-body">
              <div className="pricing-name">{"Remove ‘Powered by ACEVA GPT’"}</div>
              <div className="addon-details">
                <p>Remove the ACEVA GPT branding from chatbot</p>
              </div>
            </div>
            <div className="pricing-action">
              <div className="pricing-price">RM <span className='price-amount-badge'>10/mo</span></div>
              {/* <button className='landing-hero-action-btn'>Get Add-ons</button>  */}
            </div>
          </div>
          {/* item */}
          <div className="pricing-item addon-item">
            {/* <div className="addon-badge">Add-ons</div> */}
            <div className="addon-body">
              <div className="pricing-name">Do-It-For-You</div>
              <div className="addon-details">
                <p>We develop the chatbot for you</p>
              </div>
            </div>
            <div className="pricing-action">
              <div className="pricing-price">RM <span className='price-amount-badge'>50/mo</span></div>
              {/* <button className='landing-hero-action-btn'>Get Add-ons</button>  */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Addons