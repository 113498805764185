import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { TbPrompt } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { apiUrl } from '../Variables';
import axios from 'axios';
import MiniLoading from '../components/MiniLoading';
import BotSelectSkeleton from '../components/skeleton/BotSelectSkeleton';
import PromptBodySkeleton from '../components/skeleton/PromptBodySkeleton';
import { Helmet } from 'react-helmet';

function PromptScreen({toggleLoggedInState}) {
  const [prompt, setPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [chatbots, setChatbots] = useState([]);
  const [botId, setBotId] = useState('');
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [editIndex, setEditIndex] = useState(null); // Track the index of the prompt being edited
  const [promptLists, setPromptLists] = useState([]);
  const [promptObj, setPromptObj] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false); // Track the index of the prompt being edited
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrompt({
      ...prompt,
      [name]: value,
    });
  };
 
  useEffect(() => {
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      try {
        setLoading(true)
        const {data} = await axios.get(`${apiUrl}/chatbot`, {headers})
        setChatbots(data.map(i => { return {value:i._id, label: i.name}}))
        if (data.length === 1) {
          setBotId(data[0]._id)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('chatbot:', error)
        toast.error(`Error: ${error?.message || error?.response?.data}`)
      }
    }
    featchData()
    
  },[])
  useEffect(() => {
    // Add headers to include JWT token
    const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      axios.get(`${apiUrl}/botMenu/${botId}`, {headers}).then(res => {
        console.log(res)
        setPromptLists(res.data[0].menues)
        setPromptObj(res.data[0])
      }).catch(err => {
        console.log(err)
      })
    
  },[botId, refresh])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (promptLists.length === 5) {
      toast.error('The max number of menu must be 5!');
      return 0
    }
    setLoading(true)
    if (prompt.promptLabel && prompt.promptLink) {
      if (promptLists.length !== 0) {
       // Create a new prompt object and add it to the promptLists state
       const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      const dataObj = {
        chatbotId: botId,
        menues: [
          ...promptLists,
          { 
            title: prompt.promptLabel,
            content: prompt.promptLink
          }
        ]   
      };
      axios.put(`${apiUrl}/botMenu/${promptObj._id}`, dataObj, {headers}).then(res => {
        console.log(res)
        toast.success('Prompt updated successfully');
        // Reset the form fields
        setPrompt({ promptLabel: '', promptLink: '' });
        setRefresh(!refresh)
        setLoading(false)
      }).catch(err => {
        console.log(err)
        setLoading(false)
      })
      } else {
        // Create a new prompt object and add it to the promptLists state
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };
        const dataObj = {
          chatbotId: botId,
          menues: [
            { 
              title: prompt.promptLabel,
              content: prompt.promptLink
            }
          ]   
        };
        axios.post(`${apiUrl}/botMenu`, dataObj, {headers}).then(res => {
          console.log(res)
          toast.success('Prompt created successfully');
          // Reset the form fields
          setPrompt({ promptLabel: '', promptLink: '' });
          setRefresh(!refresh)
          setLoading(false)
        }).catch(err => {
          console.log(err)
          setLoading(false)
        })
      }
    } else {
      toast.error('Please fill in all fields');
    }
  };

  const handleEditClick = (index) => {
    // Set the current prompt values to the form for editing
    setPrompt(promptLists[index]);
    setEditIndex(index);
  };

  return (
    <>
    
      <Helmet>
        <title>Menu Option | ACEVA Dashboard</title>
      </Helmet>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        {
          loading ? <BotSelectSkeleton /> : <div className="prompt-bot-select-container">
          {
            chatbots.length > 1 ? <div className='select-bot-wrap'>
            <label htmlFor="">Choose a Bot</label>
            <select onChange={(e) => setBotId(e.target.value)} name="" id="">
              <option value={''}>{'select...'}</option>
              {
                chatbots?.map((item, index) => (
                  <option value={item.value} key={index}>{item.label}</option>
                ))
              }
            </select>
          </div> : chatbots.length === 0 ? <div className='table-data-not-found'>No data found!.</div> : ''
          }
        </div>
        }
        
        {
         loading && <PromptBodySkeleton />
        }
        {
          botId && <div className="chatbot-config-wrapper prompt-body-wrapper" style={{paddingTop:'40px'}}>
          {/* Configuration fields */}
          <div className="chatbot-config-fields">
            <div className="form-title">
              <h3>Menu options</h3>
              <p>Create menu options</p>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Prompt title */}
              <div className="form-group">
                <label htmlFor="promptLabel">Menu option title</label>
                <input
                  name="promptLabel"
                  type="text"
                  value={prompt.promptLabel}
                  onChange={handleInputChange}
                  placeholder="Menu label"
                />
              </div>
              {/* Prompt link */}
              <div className="form-group">
                <label htmlFor="promptLink">Queries</label>
                <input
                  name="promptLink"
                  type="text"
                  value={prompt.promptLink}
                  onChange={handleInputChange}
                  placeholder="Menu link"
                />
              </div>
              {/* Action button */}
              <div className="chatbot-action-wrap prompt-action-wrap">
                <button type="submit" className="chatbot-action-item active">
                  {/* <span>{editIndex !== null ? 'Update menu option' : 'Create menu option'}</span> */}
                  <span>{loading ? 'Loading...' : 'Update menu'}</span>
                </button>
              </div>
            </form>
          </div>

          {/* List of prompts */}
          <div className="prompt-wrap-container">
            <div className="form-title">
              <h3>Menu options</h3>
              <p>All list of the options</p>
            </div>
            <div className="uploaded-file-wrap prompt-wrapper">
              {
                promptLists?.length > 0 ? (
                  promptLists.map((prompt, index) => (
                    <div className="uploaded-file-item selected-item" key={index}>
                      <div className="uploaded-file-icon-and-name">
                        <TbPrompt />
                        <span>{prompt.title}</span>
                      </div>
                      <div className="uploaded-file-action created-prompt-item">
                        {/* <FiEdit
                          className="selected-icon"
                          onClick={() => handleEditClick(index)}
                        /> */}
                        <FiDelete
                          className="created-prompt-delete-icon"
                          onClick={() => {
                            // Implement delete functionality here
                            // Remove the selected prompt from the promptLists
                            const updatedPrompts = [...promptLists];
                            updatedPrompts.splice(index, 1);
                            setPromptLists(updatedPrompts);
                            toast.error('Menu option deleted successfully');
                          }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='table-data-not-found'>No data found!.</div>
                )
              }
            </div>
          </div>
        </div>
        }
        {/* {(chatbots.length === 0 && !loading) && <h6 style={{margin:'10px', color:'red'}}>You have 0 bot created. First create a bot!</h6>} */}
        {/* {loading && <MiniLoading />} */}
      </Layout>
    </>
  );
}

export default PromptScreen;
