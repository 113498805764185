import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { BiSolidPencil } from "react-icons/bi";
import { FiChevronUp } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';


import logo from '../../images/error-img.png';

function CreatePromptStep({toggleLoggedInState}) {

  // const [searchParams, setSearchParams] = useSearchParams()
  // const botId = searchParams.get('botId');
  // console.log(botId)
  const [prompt, setPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [editPrompt, setEditPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [link, setlink] = useState({
    linkLabel: '',
    linkLink: '',
  });
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  // const [promptLists, setPromptLists] = useState(JSON.parse(localStorage.getItem('menuList')) || []);
  const [promptObj, setPromptObj] = useState({});
  const [refresh, setRefresh] = useState(false);
  
  const [filterShow, setFilterShow] = useState(false)
  const [editIndex, setEditIndex] = useState(null); // Track the index of the prompt being edited
  const [loading, setLoading] = useState(false); // Track the index of the prompt being edited
  const [promptToggle, setPromptToggle] = useState(false);
  const navigate = useNavigate();

    // Update promptLists to include a 'toggle' property for each prompt
    const [promptLists, setPromptLists] = useState(
      (JSON.parse(localStorage.getItem('menuList')) || []).map((item) => ({
        ...item,
        toggle: false, // Initially set toggle to false for each prompt
      }))
    );

    console.log('promptLists', promptLists)
  
  // Function to handle toggling of individual prompt items
  const handlePromptToggle = (index) => {
    const updatedPrompts = [...promptLists];
    updatedPrompts[index].toggle = !updatedPrompts[index].toggle;
    setPromptLists(updatedPrompts);
  };
  
  const handleLinkInputChange = (e) => {
    const { name, value } = e.target;
    setlink({
      ...link,
      [name]: value,
    });
  };
  const handleLinkSubmit = (e) => {
    e.preventDefault();
    if (promptLists.length === 3) {
      toast.error('The max number of menu must be 3!');
      return 0
    }
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(link.linkLink)) {
      toast.error('URL is invalid! Please use URLs in the following format: https://www.example.com or http://www.example.com.')
      return 0
    }
    setLoading(true)
    if (link.linkLabel && link.linkLink) {
      setPromptLists([
        ...promptLists,
        { 
          title: link.linkLabel,
          content: link.linkLink
        }
      ])
      localStorage.setItem('menuList', JSON.stringify([
        ...promptLists,
        { 
          title: link.linkLabel,
          content: link.linkLink
        }
      ]))
      setLoading(false)
      setlink({ linkLabel: '', linkLink: '' });
      setRefresh(!refresh)
    } else {
      setLoading(false)
      toast.error('Please fill in all fields');
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrompt({
      ...prompt,
      [name]: value,
    });
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditPrompt({
      ...editPrompt,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (promptLists.length === 3) {
      toast.error('The max number of menu must be 3!');
      return 0
    }
    setLoading(true)
    if (prompt.promptLabel && prompt.promptLink) {
      setPromptLists([
        ...promptLists,
        { 
          title: prompt.promptLabel,
          content: prompt.promptLink
        }
      ])
      localStorage.setItem('menuList', JSON.stringify([
        ...promptLists,
        { 
          title: prompt.promptLabel,
          content: prompt.promptLink
        }
      ]))
      setLoading(false)
      setPrompt({ promptLabel: '', promptLink: '' });
      setRefresh(!refresh)
    } else {
      setLoading(false)
      toast.error('Please fill in all fields');
    }
  };

  // useEffect(() => {
  //   // Add headers to include JWT token
  //   const headers = {
  //     Authorization: `Bearer ${userInfo.token}`,
  //   };
  //   axios.get(`${apiUrl}/botMenu/${botId}`, {headers}).then(res => {
  //     console.log(res)
  //     setPromptLists(res.data[0].menues)
  //     setPromptObj(res.data[0])
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // },[botId, refresh])

  const handleEditClick = () => {
    if (editPrompt.promptLink.startsWith('http')) {
      if (!/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink)) {
        toast.error('URL is invalid! Please use URLs in the following format: https://www.example.com or http://www.example.com.')
        return 0
      }
    }
    if (editPrompt.promptLabel && editPrompt.promptLink) {
      promptLists[editIndex] = { 
        title: editPrompt.promptLabel,
        content: editPrompt.promptLink
      }
      setPromptLists([...promptLists])
      localStorage.setItem('menuList', JSON.stringify([...promptLists]))
      setEditPrompt({ promptLabel: '', promptLink: '' });
      setFilterShow(!filterShow)
    }
  };

  console.log('filterShow', filterShow)

  return (
    <>
      <div className="create-chatbot-container">

        {/* date filter */}
        <div className="chat-date-filter">

          {/* filter model */}
          <div className={filterShow ? "date-filter-model-wrapper active" : "date-filter-model-wrapper"}>
            <div className='date-filter-model prompt-edit-model'>
            {/* <BsX className='cancel-icon chat-model-cancel-icon' onClick={() => setFilterShow(!filterShow)} /> */}

            <form onSubmit={handleSubmit}>
            <div className="create-title">
              <h2>{/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Update Link' : 'Update Prompt'}</h2>
            </div>
              <div className="chatbot-config-wrapper prompt-body-wrapper">
                {/* Configuration fields */}
                <div className="chatbot-config-fields">
                  <div>
                    {/* Prompt title */}
                    <div className="form-group prompt-group">
                      <label htmlFor="promptLabel">{/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Link Button title' : 'Prompt title (e.g., About Aceva GPT)'}</label>
                      <input
                        name="promptLabel"
                        type="text"
                        value={editPrompt.promptLabel}
                        onChange={handleEditInputChange}
                        placeholder="Menu label"
                      />
                    </div>
                    {/* Prompt link */}
                    <div className="form-group prompt-group">
                      <label htmlFor="promptLink">{/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'External link' : 'Queries (e.g., Hi, what is Aceva GPT?)'}</label>
                      <input
                        name="promptLink"
                        type="text"
                        value={editPrompt.promptLink}
                        onChange={handleEditInputChange}
                        placeholder="Menu link"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </form>
                <div className="button-wrap">
                  <button type='button' className='form-button' onClick={handleEditClick}> Update </button>
                  <button onClick={() => {
                    setFilterShow(!filterShow)
                    setEditPrompt({ promptLabel: '', promptLink: '' });
                    }} className="form-button button-outline">Cancel</button>
                </div>
              </div>
          </div>
        </div>      
                  
        <div className="create-chatbot-file-wrapper">
          <div className="create-chatbot-file-body remove-scroll">
            <div className="create-title">
              <h2>Create Prompt</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="chatbot-config-wrapper prompt-body-wrapper">
                {/* Configuration fields */}
                <div className="chatbot-config-fields">
                  <div>
                    {/* Prompt title */}
                    <div className="form-group prompt-group">
                      <label htmlFor="promptLabel">Prompt title (e.g., About Aceva GPT)</label>
                      <input
                        name="promptLabel"
                        type="text"
                        value={prompt.promptLabel}
                        onChange={handleInputChange}
                        placeholder="Menu label"
                      />
                    </div>
                    {/* Prompt link */}
                    <div className="form-group prompt-group">
                      <label htmlFor="promptLink">Queries (e.g., Hi, what is Aceva GPT?)</label>
                      <input
                        name="promptLink"
                        type="text"
                        value={prompt.promptLink}
                        onChange={handleInputChange}
                        placeholder="Menu link"
                      />
                    </div>
                    {/* Action button */}
                    <div className="prompt-action-wrap">
                      <button type="submit" className="chatbot-action-item active">
                        <span>{loading ? 'Loading...' : 'Add Prompt'}</span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </form>
            
          </div>

          {/* chatbot selected item preview */}
          <div className="create-chatbot-preview-wrapper">
            <div className="create-title">
              <h2>All Prompts</h2>
              <p>Your created prompts.</p>
            </div>
            
            {/* List of prompts */}
            <div className="prompt-wrap-container">
              <div className={promptLists?.length > 1 ? "uploaded-file-wrap prompt-wrapper custom-scrollbar" : "uploaded-file-wrap prompt-wrapper custom-scrollbar scrollbar-hide"}>
                {
                  promptLists?.length > 0 ? (
                    promptLists.map((prompt, index) => (
                      <div 
                      onClick={() => setPromptToggle(!promptToggle)}
                      className={promptToggle ? "promp-item" : "prompt-item active"} 
                      key={index}
                      >
                        <div className="uploaded-file-icon-and-name">
                          <div className="prompt-title">
                            <div className='prompt-icon-text-wrap' onClick={() => handlePromptToggle(index)}>
                            <FiChevronUp className={prompt.toggle ? 'chevron' : 'chevron active'} />
                              {/* {promptToggle ? <FiChevronUp /> :  <FiChevronDown />} */}
                              <span>{prompt.title}</span>
                            </div>
                            <div className="uploaded-file-action created-prompt-item">
                              <BiSolidPencil
                                className="selected-icon"
                                onClick={() => {
                                  setFilterShow(!filterShow)
                                  setEditPrompt({promptLabel: prompt.title, promptLink: prompt.content,})
                                  setEditIndex(index)
                                }}
                                
                              />
                              <RiDeleteBinLine
                                className="created-prompt-delete-icon delete-icon"
                                onClick={() => {
                                  const updatedPrompts = [...promptLists];
                                  updatedPrompts.splice(index, 1);
                                  setPromptLists(updatedPrompts);
                                  localStorage.setItem('menuList', JSON.stringify(updatedPrompts))
                                }}
                              />
                            </div>
                          </div>
                          {prompt.toggle && (
                            <div className="prompt-content">
                              <div className="prompt-item-group">
                                <h3>Status</h3>
                                <p className="prompt-badge">{/^(ftp|http|https):\/\/[^ "]+$/.test(prompt.content) ? 'Link' : 'Prompt'}</p>
                              </div>
                              <div className="prompt-item-group">
                                <h3>Prompt title</h3>
                                <p>{prompt.title}</p>
                              </div>
                              <div className="prompt-item-group">
                                <h3>Queries</h3>
                                <p>{prompt.content}</p>
                              </div>
                            </div>
                          )}
                        </div>
                        
                      </div>
                    ))
                  ) : (
                  <div className="not-link-found">
                    <div className="not-found-image">
                      <img className='error-img' src={logo} alt="error image" />
                      <span>Nothing here beep beep... <br /> Create your prompt now!</span>
                    </div>
                  </div> 
                  )
                }
              </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default CreatePromptStep;
