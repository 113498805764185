import React from 'react'
import { Link } from 'react-router-dom'
import marketplace from '../../../images/icons/messenger.png'

function MessengerIntegration() {
  return (
    <div className="marketplace-item">
      <div className="marketplace-status">Active</div>
      <div className="marketplace-item-thumb">
        <img src={marketplace} alt="marketplace icon" />
      </div>
      <h2>Messenger Integration</h2>
      <Link to="/messenger-details">
        <button className="btn marketplace-btn">View More</button>
      </Link>
    </div>
  )
}

export default MessengerIntegration