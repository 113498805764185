import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment'

const CalendlyOAuth = () => {
  const [accessToken, setAccessToken] = useState('');
  const [meetings, setMeetings] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);

  const [error, setError] = useState('');

  const handleAuth = () => {
    try {
      window.location.href = `https://auth.calendly.com/oauth/authorize?client_id=AnCGhL8ysYyJYzDKfCCyypJipcZwefHqfBH7VtLzWW4&redirect_uri=http://localhost:3000/calendly-details&response_type=code`;
    } catch (error) {
      console.error('Error initiating authentication with Calendly:', error);
      setError('Error initiating authentication with Calendly');
    }
  };

  console.log('accessToken...', accessToken)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');

    if (authorizationCode) {
      const formData = new FormData();
      formData.append('grant_type', 'authorization_code');
      formData.append('code', authorizationCode);
      formData.append('client_id', 'AnCGhL8ysYyJYzDKfCCyypJipcZwefHqfBH7VtLzWW4');
      formData.append('client_secret', 'v1X6rj0N0fuJ6ppnfbS84nkFw1cp92IDfCN1jZr6jso');
      formData.append('redirect_uri', 'http://localhost:3000/calendly-details');

      const options = {
        method: 'POST',
        url: 'https://auth.calendly.com/oauth/token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: formData,
      };

      axios.request(options)
        .then((response) => {
          console.log('response from auth token...', response);
          setAccessToken(response.data.access_token);
        })
        .catch((error) => {
          console.error('Error exchanging authorization code for access token:', error);
          console.error('Response data:', error.response?.data);
          setError('Error exchanging authorization code for access token');
        });
    }
  }, []);

  // Extract user URL from the authentication response
  const userUrl = 'https://api.calendly.com/users/732d4c68-22cd-4db1-9764-ea5439b065bf';

  // Extract orgazinar URL from the authentication response
  const organizarUrl = 'https://api.calendly.com/organizations/f73730a1-b0ce-46b1-b002-485a4411701f';

  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
  
        const response = await fetch(`https://api.calendly.com/event_types?user=${userUrl}&organization=${organizarUrl}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const data = await response.json();
        console.log('event types data', data)
        setEventTypes(data.event_types);
      } catch (error) {
        console.error('Error fetching event types:', error);
        setError('Error fetching event types');
      }
    };
  
    fetchEventTypes();
  }, [accessToken]);

  useEffect(() => {
    const fetchScheduledEvents = async () => {
      try {
        const response = await fetch(`https://api.calendly.com/scheduled_events?user=${userUrl}&organization=${organizarUrl}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const data = await response.json();
        console.log('scheduled events data:', data);
        setMeetings(data.collection);
      } catch (error) {
        console.error('Error fetching scheduled events:', error);
        setError('Error fetching scheduled events');
      }
    };
  
    fetchScheduledEvents();
  }, [accessToken]);
  
  

  return (
    <div>
      <button className="btn marketplace-btn outline" onClick={handleAuth}>Authenticate with Calendly</button>
      {accessToken && (
        <div>
          <h2>Calendar Meetings</h2>
          <table>
            <thead>
              <tr>
                <th>Duration</th>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Meeting start</th>
                <th>Meeting end</th>
                {/* Add more table headers as needed */}
              </tr>
            </thead>
            <tbody>
              {meetings?.map((meeting) => (
                <tr key={meeting.uuid}>
                  <td>{meeting.name}</td>
                  <td>{meeting.event_memberships?.map((item) => item?.user_name)}</td>
                  <td>{meeting.event_memberships?.map((item) => item?.user_email)}</td>
                  <td>{meeting.status}</td>
                  <td>{moment(meeting.created_at).format('lll')}</td>
                  <td>{moment(meeting.end_time).format('lll')}</td>
                  {/* Add more table cells for other meeting properties */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
  
};

export default CalendlyOAuth;
