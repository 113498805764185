import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import Accordion from '../components/Accordion'
import FooterMenu from '../components/landing-page/FooterMenu'
import HeaderMenu from '../components/landing-page/HeaderMenu'
import LandingStepSection from '../components/landing-page/LandingStepSection'
import LiveDemoSection from '../components/landing-page/LiveDemoSection'
import heroThumb from '../images/bg/new-hero.png'

import { useLanguage } from '../context/LanguageContext'
import translations from '../context/Translations'

function LandingPageScreen() {
  const {lanCode} = useParams()
  const { language, setLanguage } = useLanguage();
  if (lanCode) {
    if (lanCode === 'en') {
      setLanguage('english');
    } else if (lanCode === 'ms') {
      setLanguage('malay');
    } else if (lanCode === "ind") {
      setLanguage('indonesia');
    }

  }
  const t = translations[language];

  const [openItemIndex, setOpenItemIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (index === openItemIndex) {
      // Clicking on the open item should close it
      setOpenItemIndex(null);
    } else {
      // Clicking on a closed item should open it and close others
      setOpenItemIndex(index);
    }
  };

  return (
    <>
    
      <Helmet>
        <title>Home | Aceva GPT</title>
      </Helmet>
      <div className="landing-page-canvas">
        
        <HeaderMenu />

        <div className="landing-container hero-container">
          <div className="landing-hero-wrap">
            <div className="landing-hero-content hero-content">
              <h1>{t?.heroSection?.title?.custom} <span className="brand-color">{t?.heroSection?.title?.chatGPT}</span> {t?.heroSection?.title?.forYour} <span className="brand-color">{t?.heroSection?.title?.business}</span></h1>
              <p>{t?.heroSection?.subTitle}</p>

              <div className="landing-hero-action">
                <Link to="/register">
                  <button className='landing-hero-action-btn'>{t?.heroSection?.button}</button>
                </Link>
                <span>{t?.heroSection?.noCardRequired}</span>
              </div>
            </div>
            <div className="landing-hero-thumb">
              {/* <img src="https://siamcomputing.com/wp-content/uploads/2022/05/Chatbot.png" alt="hero thumb" /> */}
              <img src={heroThumb} alt="hero thumb" />
            </div>
          </div>
        </div>

        <LandingStepSection t={t} />

        {/* video demo */}
        <section className="full-width-container video-section">
          <div className="landing-container">
            <div className="video-demo-wrapper">
              <div className="landing-title">
                <h2>{t?.videoSection?.title}</h2>
                <p>{t?.videoSection?.subTitle}</p>
              </div>
              <iframe width="100%" height="415" src="https://www.youtube.com/embed/8KIUI1yUwg8?si=DCedC52o4_672UIE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </section>

        {/* live demo */}
        <LiveDemoSection t={t} />
        {/* <LandingLiveDemoSection /> */}

        {/* call to action */}
        <section className="call-to-action-section">
          <div className="call-to-action-wrapper landing-container">
              <div className="landing-hero-content">
                <h1>{t?.ctaSection?.title}</h1>
                <p>{t?.ctaSection?.subTitle}</p>

                <div className="landing-hero-action">
                  <Link to="/pricing">
                    <button className='landing-hero-action-btn'>{t?.ctaSection?.button}</button>
                  </Link>
                </div>
              </div>
          </div>
        </section>

        {/* accordion */}
        <section className="faq-section">
        <div className="landing-container accordion-container">
          <div className="landing-title-center">
            <div className="landing-hero-content">
              <h2>{t?.faqSection?.title}</h2>
              {/* <p>Create your own AI chatbot with no code and watch it learn from your content in real-time.</p> */}
            </div>
          </div>
          <div className="landing-accordion-wrapper">
            {t?.faqSection?.faqs?.map((item, index) => (
                <Accordion
                  key={index}
                  title={item.question}
                  isOpen={index === openItemIndex}
                  onClick={() => handleAccordionClick(index)}
                >
                  {item.answer}
                </Accordion>
              ))}
          </div>
        </div>
        </section>

        {/* footer */}
        <FooterMenu t={t} />
      </div>
    </>
  )
}

export default LandingPageScreen