import React from 'react'
import Layout from '../components/Layout'

function HelpCenterScreen({toggleLoggedInState}) {
  return (
      <>
        <Layout toggleLoggedInState={toggleLoggedInState}>
          <div className="container">
            <div className="title">
              <h2>Help center</h2>
            </div>
            <div className="help-center-wrapper">
              {/* item */}
              <div className="help-center-box">
                <div className="help-center-video">
                  <iframe src="https://www.youtube.com/embed/rSAtsu2D_rs?si=xVbyhD9azg4GQ2bh&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="help-center-content">
                  <h2>How to create WhatsApp Chatbot</h2>
                </div>
              </div>
              {/* item */}
              <div className="help-center-box">
                <div className="help-center-video">
                  <iframe src="https://www.youtube.com/embed/zIKR2J1AZC4?si=iqaO2GpKTeY3LJpj&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="help-center-content">
                  <h2>How to do Messenger Integration</h2>
                </div>
              </div>
              {/* item */}
              <div className="help-center-box">
                <div className="help-center-video">
                <iframe src="https://www.youtube.com/embed/oCkv7AxcjaU?si=G0mems1IscPk0JAo&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="help-center-content">
                  <h2>How to do Google Calendar Integration</h2>
                </div>
              </div>
              {/* item */}
              <div className="help-center-box">
                <div className="help-center-video">
                  <iframe src="https://www.youtube.com/embed/6T14pRBNhLA?si=eUNZDWyKaNDq3jQL&amp;controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="help-center-content">
                  <h2>How to create a Website Chatbot</h2>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
  )
}

export default HelpCenterScreen