import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

function ConfigColor({ config, setConfig }) {
  const [color, setColor] = useState(config?.brandColor || '#009688'); // Initial color is white

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    setConfig({ ...config, brandColor: newColor.hex });
  };

  useEffect(() => {

  },[config])

  console.log('color', color);

  return (
    <div className='color-title'>
      <span>Brand Color</span>
      <ChromePicker color={config?.brandColor || color} onChange={handleColorChange} />
      <div className='brand-color-selected' style={{ marginTop: '20px' }}>
        Selected brand color: <div className='selected-color' style={{ background: config?.brandColor || color }}></div>
      </div>
    </div>
  );
}


export default ConfigColor;
