import { Drawer, Space } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../Variables';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BiSolidChat, BiSolidPencil } from 'react-icons/bi';
import { BsAndroid2, BsBookFill, BsSpeedometer2 } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { IoStorefrontSharp } from 'react-icons/io5';
import { VscMenu } from 'react-icons/vsc';
import { NavLink, useNavigate } from 'react-router-dom';

const SidebarDrawer = () => {
  const [chatbots, setChatbots] = useState([]);
  const [botUpgrade, setBotUpgrade] = useState(false);

  // Fetch userInfo from localStorage or set it to null if not found
  const userInfoFromLocalStorage = localStorage.getItem('userInfo');
  const initialUserInfo = userInfoFromLocalStorage ? JSON.parse(userInfoFromLocalStorage) : null;

  const [userInfo, setUserInfo] = useState(initialUserInfo);

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showDefaultDrawer = () => {
    setSize('default');
    setOpen(true);
  };

  const navigate = useNavigate();

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userInfo) { // Check if userInfo is not null
      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };

      const fetchData = async () => {
        try {
          const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
          setChatbots(data);
        } catch (error) {
          console.log('scrap list fetching fail:', error);
        }
      };
      fetchData();
    }
  }, [userInfo]); // Make useEffect dependent on userInfo to re-run when it changes


  return (
    <>
      <Space>
        {/* <RxHamburgerMenu onClick={showDefaultDrawer} /> */}
        <VscMenu
          onClick={showDefaultDrawer}
          className="burger-menu-icon header-menu-icon"
        />
      </Space>
      <Drawer
        // title={`${size} Drawer`}
        placement="left"
        size={size}
        onClose={onClose}
        open={open}
      >
        <aside className="sidebar">
          <ul className="sidebar">
            <button
              className={
                chatbots?.length ===
                parseInt(userInfo?.meta_data?.planData?.meta_data?.bot)
                  ? 'form-button disabled'
                  : 'form-button'
              }
              onClick={() => {
                if (
                  chatbots?.length >=
                  parseInt(userInfo?.meta_data?.planData?.meta_data?.bot)
                ) {
                  setBotUpgrade(true);
                  toast.error(
                    'You are currently limited to only 1 bot. Upgrade your plan now for more bots!'
                  );
                } else {
                   // Cleaning up local storage after successful updates
                  localStorage.removeItem("botConfig");
                  localStorage.removeItem("botInfo");
                  localStorage.removeItem("menuList");
                  localStorage.removeItem("selectedLinks");
                  navigate('/chatbot-type');
                  // toast.error("You are currently limited to only 1 bot.")
                }
              }}
            >
              <FiPlus color="white" /> <BsAndroid2 color="white" />
              <span style={{ whiteSpace: 'nowrap' }}>Create New Chatbot</span>
            </button>

            <li className="grouped-item">
              <p className="sub-heading">My Dashboard</p>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                <div className="flex-label-icon">
                  {/* <MdSpaceDashboard /> */}
                  <BsSpeedometer2 />
                  {<span>Dashboard</span>}
                </div>
              </NavLink>
            </li>

            <li className="grouped-item">
              <p className="sub-heading">Chatbot</p>
              <NavLink
                to="/chatbot-list"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                <div className="flex-label-icon">
                  {/* <FaRobot /> */}
                  <BiSolidPencil />
                  {<span>Edit Chatbot(s)</span>}
                </div>
              </NavLink>
            </li>

            <li className="grouped-item">
              <p className="sub-heading">LINKS AND FILES</p>
              <NavLink
                to="/file-list"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                <div className="flex-label-icon">
                  {/* <AiOutlineLink /> */}
                  <BsBookFill />
                  {<span>Library</span>}
                </div>
              </NavLink>
            </li>

            <li className="grouped-item">
              <p className="sub-heading">Advanced</p>
              <NavLink
                to="/chatlogs"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                <div className="flex-label-icon">
                  {/* <BsChatText /> */}
                  <BiSolidChat />
                  {<span>Chatlogs</span>}
                </div>
              </NavLink>
            </li>

            <li className="grouped-item">
              <p className="sub-heading">ADD ONS</p>
              <NavLink
                to="/marketplace"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                <div className="flex-label-icon">
                  {/* <BsChatText /> */}
                  <IoStorefrontSharp />
                  {<span>Marketplace</span>}
                </div>
              </NavLink>
            </li>

            {
              // data?.map((item, index) => (
              //   <li key={index} className={toggle ? "" : "dropdown-menu-item active"} onClick={() => setToggle(!toggle)} >
              //     <NavLink to={item.path} className={({ isActive }) =>isActive ? "active-link" : ""}>
              //       <div className='flex-label-icon'>
              //       {item.icon}
              //       { sidebarToggle === false && <span>{item.label}</span> }
              //       </div>
              //       { item?.dropdown && <FiChevronDown className='dropdown-icon' /> }
              //     </NavLink>
              //     {/* sub menu */}
              //     { item?.dropdown && (
              //       <div className="sub-menu-wrapper">
              //         <ul>
              //           {
              //             item?.subMenu?.map((sub, subIndex) => (
              //               <li onClick={() => setToggle(!toggle)} key={subIndex}>
              //                 <NavLink to={sub?.path} className={({ isActive }) =>isActive ? "active-link" : ""}>
              //                   <div className='flex-label-icon'>
              //                   {sub?.icon}
              //                   <span>{sub?.label}</span>
              //                   </div>
              //                 </NavLink>
              //               </li>
              //             ))
              //           }
              //         </ul>
              //       </div>
              //     ) }
              //   </li>
              // ))
            }
          </ul>
        </aside>
      </Drawer>
    </>
  );
};
export default SidebarDrawer;

// Chatbot created successfully.
// Chatbot embed link copied.
// Chatbot deleted.
// You are currently limited to only 1 bot. Upgrade your plan now for more bots!
// Retraining completed.
// Retraining failed. Try again.
// Retraining failed due to server error. Try again later.
