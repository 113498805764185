import React, {useState} from 'react';
import Select from 'react-select';
import ConfigBot from '../ConfigBot';

  function UpdateLanguageSteps({config, setConfig}) {

  const language = [
    {value: 'en-GB', label: 'English (UK)'},
    {value: 'hi', label: 'Hindi'},
    {value: 'id', label: 'Indonesian'},
    {value: 'ms', label: 'Malay'},
    {value: 'zh-CN', label: 'Chinese (PRC)'}
  ]

  // Ensure config.language is properly initialized as an array
  if (!config.language || !Array.isArray(config.language)) {
    config.language = [];
  }


  return (
    <div className="create-chatbot-container create-chatbot-file-wrapper remove-after-line update-grid-gap">
      <div className="create-chatbot-file-wrapper update-grid-gap">
        <div className="create-chatbot-file-body remove-scroll">
          <div className="create-title">
            <h2>Language</h2>
          </div>
          <form>
            <div className="chatbot-config-wrapper prompt-body-wrapper">
              <div className="chatbot-config-fields">
                <div className="form-group prompt-group">
                  <label htmlFor={`Primary language`}>
                    Select the <strong className="bold-lan">Primary language</strong> for your bot.
                  </label>
                  <div className="language-input-wrapper">
                    <Select
                      options={language}
                      placeholder="None"
                      isSearchable
                      value={config?.language[0] || null}
                      onChange={(selected) => {
                        const updatedConfig = { ...config }; // Clone the config object
                        updatedConfig.language[0] = selected;
                        setConfig(updatedConfig);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group prompt-group">
                  <label htmlFor={`Secondary language`}>
                    Select the <strong className="bold-lan">Secondary language</strong> for your bot.
                  </label>
                  <div className="language-input-wrapper">
                    <Select
                       options={[{value: null, label: 'None'}, ...language.filter(item => !config.language.map(item => item.value).includes(item.value))]}
                      placeholder="None"
                      isSearchable
                      value={config?.language[1] || null}
                      onChange={(selected) => {
                        const updatedConfig = { ...config }; // Clone the config object
                        updatedConfig.language[1] = selected;
                        setConfig(updatedConfig);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group prompt-group">
                  <label htmlFor={`Tertiary language`}>
                    Select the <strong className="bold-lan">Tertiary language</strong> for your bot.
                  </label>
                  <div className="language-input-wrapper">
                    <Select
                      options={[{value: null, label: 'None'}, ...language.filter(item => !config.language.map(item => item.value).includes(item.value))]}
                      placeholder="None"
                      isSearchable
                      value={config?.language[2] || null}
                      onChange={(selected) => {
                        const updatedConfig = { ...config }; // Clone the config object
                        updatedConfig.language[2] = selected;
                        setConfig(updatedConfig);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="create-chatbot-preview-wrapper">
        <ConfigBot mask={true} config={config} setConfig={setConfig} /> 
      </div>        
    </div>
  );
}

export default UpdateLanguageSteps;
