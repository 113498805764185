import { Modal, Table } from 'antd';
import React, { useState } from 'react';
import calendlyLogo from '../../../images/icons/calendly.png';

function CalendlyIntegration() {
  const [showInlineWidget, setShowInlineWidget] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dataSource = [
    {
      key: '1',
      field: 'Bot Name',
      value: 'Wipdata AI',
    },
    {
      key: '2',
      field: 'Account',
      value: 'Cash30Trading@gmail.com',
    },
    {
      key: '3',
      field: 'Event Name',
      value: 'Booking Appointment 1',
    },
    {
      key: '4',
      field: 'Calendly URL',
      value: 'https://calendar.google.com/calendar/u/0/r?pli=1',
    },
  ];

  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="marketplace-item">
        <div className="marketplace-status">Active</div>
        <div className="marketplace-item-thumb">
          <img src={calendlyLogo} alt="calendly logo" />
        </div>
        <h2>Calendly Integration</h2>
        <button className="btn marketplace-btn" onClick={showModal}>View Details</button>
        <Modal 
          className='marketplace-modal'
          title="Calendly Integration" 
          visible={isModalVisible} 
          onOk={handleOk} 
          onCancel={handleCancel}
          footer={[
            <div style={{display: 'flex', gridGap: '2px'}}>
              <button className="btn marketplace-btn dangar" key="delete" onClick={handleOk}>Delete</button>,
            <button className="btn marketplace-btn" key="close" onClick={handleCancel}>Close</button>
            </div>
          ]}
        >
          <Table className='modal-table' dataSource={dataSource} columns={columns} pagination={false} />
        </Modal>
      </div>
    </>
  );
}

export default CalendlyIntegration;
