import React from 'react'
import Layout from '../components/Layout'
import ChatbotSteps from '../components/antd/ChatbotSteps'

function StepScreen() {
  return (
    <>
      <Layout>
          <div className="chatbot-step-title">
            <h1>Create Chatbot </h1>
            <p>Start your chatbot journey.</p>
          </div>
          <div className="chatbot-steps-wrapper">
            <ChatbotSteps />
          </div>
      </Layout>
    </>
  )
}

export default StepScreen