
import React from 'react';
import ConfigBot from '../../components/ConfigBot';

import Skeleton from 'react-loading-skeleton';

function UpdatePreviewSteps({config, setConfig, promptLists}) {

  return (
    <>
      <div className="create-chatbot-container create-chatbot-final-container remove-after-line">
        
        <div className="create-chatbot-file-wrapper create-chatbot-final-wrapper ">
          {/* chatbot selected item preview */}
          <div className="create-chatbot-final-step">
            {
              !config ? <div>
                  <Skeleton width={400} height={500} />
                </div> : <ConfigBot config={config} setConfig={setConfig} promptLists={promptLists} />
            }
          </div>
          
          <div className="create-chatbot-final-content preview-title">
            <h2>This is how your chatbot currently looks like.</h2>
          </div>
        </div>
      </div> 
    </>
  )
}

export default UpdatePreviewSteps