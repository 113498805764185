import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import chatbotIcon from '../../images/icons/robot.png';
import WhiteCanvas from '../WhiteCanvas';

function AdminMessageBody({ setLoading, showChat, setShowChat, chats, bot}) {
  const [message, setMessage] = useState('');
  const [chatDetails, setChatDetails] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const chatRef = useRef(null);
  const botRef = useRef(null);

  // fetch chats
  // useEffect(() => {
  //   setChatDetails(null);
  //   if (chatDetailsId) {
  //     const fetchChats = async () => {
  //       setLoading(true);
  //       try {

  //         // Retrieve userInfo from localStorage
  //         const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  //         if (!userInfo || !userInfo.token) {
  //           console.error('User token not found.');
  //           return;
  //         }

  //         // Add headers to include JWT token
  //         const headers = {
  //           Authorization: `Bearer ${userInfo.token}`,
  //         };

  //         const response = await axios.get(`${apiUrl}/chats/${chatDetailsId}`, {headers});
  //         console.log('response', response)
  //         setChatDetails(response.data);
  //       } catch (err) {
  //         setError(err.message);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchChats();
  //   }
  // }, [chatDetailsId]);

  console.log('chat details:', chats)

  // submit or send message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    // Your logic to send a message goes here
  };

  // Scroll logic - Uncomment and integrate as needed
  // useEffect(() => {
  //   chatRef.current?.scrollIntoView({ behavior: "smooth" });
  //   if (error) {
  //     toast('Error');
  //   }
  // }, [message, chat]);

  // useEffect(() => {
  //   botRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, []);

  useEffect(() => {

  }, [chatDetails])

  console.log('chatDetails', chatDetails)
  console.log('bot', bot)

  return (
    <>
      <ToastContainer />
      <div className="chatbox-wrapper">
        {/* chatbot widget wrapper */}
        {
          bot == null ? (
            <WhiteCanvas
              parentClass="chat-init-not-found"
              h2="Start your first conversation"
              p="Click on the left side channel or group, and start messaging."
              icon={<img src={chatbotIcon} alt="drag" />}
            />
          ) : (
            <div className="chatbox">
              {/* chat header */}
              <div className="chatbot-widget-header chatbox-header active chatlogs-header" style={{background:bot?.brandColor}}>
                {/* agent presence */}
                <div className="chatbot-widget-agent-precence">
                  {/* agent left side */}
                  <div className="chatbot-widget-header-left">
                    {/* agent avatar */}
                    {/* <div className="chatbot-recipient-avatar chatbox-recipient-avatar">
                      <img src={bot?.logo ? bot?.logo : ''} alt="agent avatar" />
                      <div className="online-status"></div>
                    </div> */}
                    {/* agent details */}
                    <div className="chatbot-widget-recipient-details chatbox-recipient-details">
                      <h3 style={bot?.brandColor && {color:'white'}}>{bot?.botName ? bot?.botName : bot ? 'Bot has deleted, only chatlogs available!' : ''}</h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* chat conversation group body */}
              <div className="chat-conversation-wrapper chatbox-conversation">
                <ul className='message-body'>
                  {/* Messages rendering logic */}
                  {chats?.map((item, index) => (
                    // Render your messages here
                    <div key={index} className="chat-message-wrapper">
                      {/* Render user, bot, and agent messages */}
                      {/* Example for user message */}
                      {item.user && (
                        <div className="text-and-date-wrap">
                          <li className="message-bubble-visitor date-bubble-item chatlog-user-agent" style={{background:bot?.brandColor}}>
                            {item.user}
                          </li>
                          <p className='date-badge'>{moment(item?.time).fromNow()}</p>
                        </div>
                      )}
                      {/* Example for bot message */}
                      <div className="chat-message-wrapper">
                      <div className="text-and-date-wrap">
                        <li className="message-bubble-agent chatlog-bot-agent">
                          <span dangerouslySetInnerHTML={{ __html: item?.bot?.text.replace(/\n/g, '<br />') }}></span>
                          {/* <p className='date-badge'>{moment(item?.time).fromNow()}</p> */}
                        </li>
                        <p className='date-badge date-badge-bot'>{moment(item?.time).fromNow()}</p>
                        </div>
                      </div>
                      <li ref={botRef}></li>
                    </div>
                  ))}
                </ul>
              </div>

              {/* chat footer */}
              {/* <form className='chatbox-conversation-form' onSubmit={handleSendMessage}>
                <div className="chatbot-footer chatbox-footer">
                  <div className="chatbot-footer-input-box">
                    <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" placeholder='Start conversation...' />
                    <button type='submit' className='chatbot-send-message-button chatlogs-send-btn'>
                      Send
                    </button>
                  </div>
                </div>
              </form> */}
            </div>
          )
        }
      </div>
    </>
  );
}

export default AdminMessageBody;
