import { Table } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../../Variables';
import React, { useEffect, useState } from 'react';
import { BsAndroid2, BsX } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import marketplace from '../../../images/icons/whatsapp.png';


function WhatsappIntegration() {

  const [chatbots, setChatbots] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [reportModel, setReportModel] = useState(false);

  useEffect(() => {
      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };

      const fetchData = async () => {
        setLoading(true)
        try {
          const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
          setChatbots([...data.filter(item => item.configData.type === "whatsapp-bot").map(ite => {
            return {...ite, ...ite.configData}
          })]);
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log('scrap list fetching fail:', error);
        }
      };
      fetchData();
  }, []); 

  // Define the columns for the table
  const columns = [
    {
      title: 'Bot Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'WABA ID',
      dataIndex: 'wabaId',
      key: 'wabaId',
    },
    {
      title: 'WABA App',
      dataIndex: 'waAppName',
      key: 'waAppName',
    }
  ];

  return (
    <>
      <div className="marketplace-item">
        {
          userInfo.whatsappStatus === 'integrationDone' && <div className="marketplace-status">Active</div>
        }
        <div className="marketplace-item-thumb">
          <img src={marketplace} alt="marketplace icon" />
        </div>
        <h2>WhatsApp Integration</h2>
        {
          loading ? <button className="btn marketplace-btn">Loading...</button> : userInfo.whatsappStatus === 'integrationPending' ? <button onClick={() => setShowPopup(!showPopup)} className="btn marketplace-btn">View Details</button> : chatbots.length > 0 ? <button onClick={() => setReportModel(!reportModel)} className="btn marketplace-btn">View Details</button> : userInfo.whatsappStatus === 'integrationDone' ? <button onClick={() => setSuccessModel(!successModel)} className="btn marketplace-btn">View Details</button> : <Link to="/whatsapp-details">
          <button className="btn marketplace-btn">View More</button>
        </Link>
        }  
        {/* <button onClick={() => setReportModel(!reportModel)} className="btn marketplace-btn">Successful</button> */}

      </div>

      {showPopup && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap view-details-popup">
              <BsX onClick={() => setShowPopup(!showPopup)} className='cancel-icon' />
              <h3>WhatsApp Integration</h3>

              <div className="view-details-content">
                <p>Please check your email for the confirmation.</p>
                <p> We required information below for the next step: </p>
                <ul>
                  <li>Business Name:</li>
                  <li>WhatsApp Business Number:</li>
                  <li>Facebook Business Manager ID:</li>
                  <li>Any specific features you would like to prioritize:</li>
                </ul>
              </div>
              
              <button className='btn marketplace-btn' onClick={() => setShowPopup(!showPopup)}>Done</button>
              
            </div>
          </div>
        )}

        {successModel && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap view-details-popup">
              <BsX onClick={() => setSuccessModel(!successModel)} className='cancel-icon' />
              <h3>WhatsApp Integration</h3>

              <div className="view-details-content">
                <p>Congratulations! your whatsapp is successfully connected to WhatsApp Business Platform.</p>
                <p>So, what’s next?</p>
                <p> You can start creating you Whatsapp chatbot by clicking the button below  </p>
              </div>

              <Link to="/create-whatsapp-chatbot" onClick={() => {
                 // Cleaning up local storage after successful updates
                  localStorage.removeItem("botConfig");
                  localStorage.removeItem("botInfo");
                  localStorage.removeItem("menuList");
                  localStorage.removeItem("selectedLinks");
              }}>
                <div className='download-btn-wrap'>
                  <FiPlus />
                  <BsAndroid2 />
                  <span>Create new chatbot</span>
                </div>
              </Link>
              
            </div>
          </div>
        )}

        {reportModel && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap view-details-popup">
              <BsX onClick={() => setReportModel(!reportModel)} className='cancel-icon' />
              <h3>WhatsApp Integration</h3>

              <div className="view-details-content">
                <p><strong>NOTE:</strong> You can only add one number per Bot.</p>
              </div>

              <Table columns={columns} dataSource={chatbots} pagination={false} />

              <div className="chatbot-type-action">
                {/* <button onClick={() => setReportModel(!reportModel)} className="default-button waba-id-btn">Delete</button> */}
                <button onClick={() => setReportModel(!reportModel)} className="default-button chatbot-type-btn"> Close</button>
              </div>
              
            </div>
          </div>
        )}
    </>
  )
}

export default WhatsappIntegration