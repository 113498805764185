import { Calendar, theme } from 'antd';
import moment from 'moment'; // Import Moment.js
import React from 'react';

const AntdTable = ({ onDateSelect }) => {
  const { token } = theme.useToken();
  const wrapperStyle = {
    width: '100%',
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
    margin: '30px 0px',
  };

  const onSelect = value => {
    onDateSelect(value.format('YYYY-MM-DD'));
  };

  return (
    <div style={wrapperStyle}>
      <Calendar
        fullscreen={false}
        onPanelChange={(value, mode) => {}}
        disabledDate={(date) => {
          // if (date.endOf('d').valueOf() < moment().startOf(date) || (new Date(date).getDay() === 0 ||
          // new Date(date).getDay() === 6)) {
          if (date.endOf('d').valueOf() < moment().startOf(date)) {
               return true;
          }
          return false;
        }}
        onSelect={onSelect}
      />
    </div>
  );
};

export default AntdTable;
