import React from 'react';
import Slider from 'react-slick';
import { BsChevronLeft, BsChevronRight, BsX } from 'react-icons/bs';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import guideSix from '../../images/bg/666.png';
import guideSeven from '../../images/bg/777.png';

function IframeGuideVideoModel({ videoModel, setVideoModel }) {

  return (
    <>
      <div className={videoModel ? 'tut-demo-model-container active' : 'tut-demo-model-container'}>
        <div className="demo-video-modal-wrapper">
          <BsX className="cancel" onClick={() => setVideoModel(!videoModel)} />

          <Slider
            prevArrow={<BsChevronLeft className="slick-prev" />}
            nextArrow={<BsChevronRight className="slick-next" />}
          >
            <div className="guide-slider">
              <h3>1. Copy & Paste the code snippet</h3>
              <img className='img' src={guideSix} alt="Slide 1" />
            </div>
            <div className="guide-slider">
              <h3>2. Or add the plugin (Wordpress)</h3>
              <img className='img' src={guideSeven} alt="Slide 2" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default IframeGuideVideoModel;
