import { Popover, Radio } from 'antd';
import React from 'react';
import { IoMdInformationCircle } from "react-icons/io";


const SelectPrice = ({value, setValue}) => {

  const onChange = e => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const essentialContent = (
    <>
      <p>2 chatbots</p>
      <p> 1000 Service Conversations / month</p>
    </>
);

const growthContent = (
  <>
    <p>5 chatbots</p>
    <p>1000 Service Conversations / month</p>
  </>
)

  return (
    <>  
      <label htmlFor="">Choose a plan:</label>
      <Radio.Group className='price-radio-group' onChange={onChange} value={value}>
      <Radio className='price-radio' value={'price_1OmguPFBC3QMoaI4utnDFPip'}>
        <span className="price-tag-label">Essential</span>
        <div className='price-tag-value'>RM 90/mo</div>
        <div className="popover-wrap">
              <Popover content={essentialContent}>
                <IoMdInformationCircle />
              </Popover>
            </div>
      </Radio>
      <Radio className='price-radio' value={'price_1OmhSzFBC3QMoaI4jGmgotEN'}>
        <span className="price-tag-label">Growth</span>
        <div className='price-tag-value'>RM 235/mo</div>
        <div className="popover-wrap">
              <Popover content={growthContent}>
                <IoMdInformationCircle />
              </Popover>
            </div>
      </Radio>
    </Radio.Group>
    </>
  );
};


export default SelectPrice;
