import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { apiUrl } from '../Variables';
import Logo from '../images/aceva.png';
import PricingCardSkeleton from '../components/skeleton/PricingCardSkeleton';

function Repayment() {
  const {userId} = useParams();
  const [loading, setLoading] = useState('true') 
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [planLists, setPlanLists] = useState([]);
  const [featchLoading, setFetchLoading] = useState(false)
  const [pid, setId] = useState('')

  useEffect(() => {
    const fetchApis = async () => {
      // Add headers to include JWT token
      const headers = {
       Authorization: `Bearer ${userInfo?.token}`,
     };
     setFetchLoading(true)
     await axios.post(`${apiUrl}/user/token_by_id`, { userId }).then(res => {
      console.log(res)
      setUserInfo(res.data)
    }).catch(err => {
      console.log(err)
    })
     await axios.get(`${apiUrl}/plan`, {headers}).then(res => {
       console.log(res)
       setPlanLists(res.data)
       setFetchLoading(false)
     }).catch(err => {
       console.log(err)
       setFetchLoading(false)
     })
    }
    fetchApis()
  }, []);

  // const payNow = async (pid) => {
  //   setLoading(true)
  //   setId(pid)
  //   const headers = {
  //     Authorization: `Bearer ${userInfo.token}`,
  //   };
  //   try {
  //     const response = await axios({
  //       url: `${apiUrl}/stripe/create-checkout-session`,
  //       method: 'post',
  //       data: {
  //         items: [
  //           {
  //             id: pid,
  //             quantity: 1
  //           }
  //         ],
  //         token: userInfo.token
  //       },
  //       headers
  //     });
  //     console.log(response)
  //     setLoading(false)
  //     // Clear the local storage
  //     window.location = response.data.url
  //     localStorage.clear();
  //   } catch (error) {
  //     setLoading(false)
  //     console.log(error);
  //   }
  // };

  const payNow = async (pid, value) => {
    setLoading(true)
    setId(pid)
    const headers = {
      Authorization: `Bearer ${userInfo?.token}`,
    };
    try {
      const response = await axios({
        url: `${apiUrl}/stripe/subscriptionCheckout`,
        method: 'post',
        data: {
          items: [ { priceId: value, quantity: 1 } ],
          token: userInfo?.token
        },
        headers
      });
      console.log(response)
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      // localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };
  return (
    <>
      <div style={{width:'100%', borderBottom:'1px solid #bdbdbd'}}><img style={{padding:'20px', width:'110px'}} src={Logo}/></div>
        <div className="billing-wrapper">
      {/* add new plan */}
      <div className="form-title">
            <h2 style={{textAlign:'center', marginBottom:'20px'}}>Subscription Plan</h2>
            <p style={{textAlign:'center'}}>Subscribing your subscription is quick and easy. Simply click Subscribe button to subscribe <br />and continue enjoying all the features and perks that come with it. </p>
          </div>

            {
              featchLoading ? (
                <PricingCardSkeleton />
              ) : (
                <div className="pricing-wrapper">
                {/* item */}
                  {
                    planLists?.filter(i => i._id !== '6511e925469414f7e4262d88')?.map((item, index) => (
                      <div key={index} className="pricing-item">
                        <div className="pricing-title">
                        <h3 className="pricing-name">{item?.name}</h3>
                        <p>{item?.description}</p>
                        </div>
                        <ul className='pricing-lists'>
                          {
                            item?.features?.map((feature, fIndex) => (
                              <li key={fIndex}> <span className='pricing-dot'></span>  {feature}</li>
                            ))
                          }
                        </ul>
                        <div className="pricing-action">
                          <div className="pricing-price">RM <span className='price-amount-badge'>{item?.price}/mo</span></div>
                          <button onClick={() => payNow(item?._id, item?.stripePriceId)} className='landing-hero-action-btn'>
                            {(loading && pid === item?._id) ? 'Loading...' : 'Subscribe'}</button> 
                        </div>
                      </div>
                    ))
                  }
                  
                </div>
              )
            }
            </div>
        
    </>
  )
}

export default Repayment