import React from 'react'
import { Helmet } from 'react-helmet'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import CTASection from '../../components/CTASection'
import FooterMenu from '../../components/landing-page/FooterMenu'
import HeaderMenu from '../../components/landing-page/HeaderMenu'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'

function TermsAndConditionScreen() {

  const { language } = useLanguage();
  const t = translations[language];
  const content = translations[language]?.termsConditions?.description; 

  return (
    <>
    
      <Helmet>
        <title>Terms and conditions | Aceva GPT</title>
      </Helmet>
      <HeaderMenu t={t} />

        <div className="landing-container page-details-container">
          <div className="page-details-meta">
            <h1>{t?.termsConditions?.title}</h1>
            <p>{t?.termsConditions?.subTitle}</p>
          </div>

          <div className="page-details-content">
            <div className="page-details-content-block">
              <h2>{content?.billingRefunds?.title}</h2>
              <p>{content?.billingRefunds?.accessCharge}</p>
              <p>{content?.billingRefunds?.noRefunds}</p>
              <p>{content?.billingRefunds?.cancelSubscription}</p>
              <p>{content?.billingRefunds?.changePricing}</p>
            </div>

            <div className="page-details-content-block">
              <h2>{content?.emails?.title}</h2>
              <p>{content?.emails?.content}</p>
            </div>

            <div className="page-details-content-block">
              <h2>{content?.conditions?.title}</h2>
              <p>{content?.conditions?.content1}</p>
              <p>{content?.conditions?.content2}</p>
            </div>

            <div className="page-details-content-block suggest-link">
              <Link to="/privacy-policy">
                {content?.privacyPolicy} <BsArrowRight />
              </Link>
            </div>

            <CTASection />
          </div>
        </div>

      <FooterMenu t={t} />
    </>
  )
}

export default TermsAndConditionScreen