import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MyAccountTab from '../components/settings/MyAccountTab';
import BillingTab from '../components/settings/BillingTab';
import { apiUrl } from '../Variables';
import axios from 'axios';
import { Helmet } from 'react-helmet'

function SettingsScreen({toggleLoggedInState}) {

  const [userDetails, setUserDetails] = useState({})
  const [settings, setSettings] = useState({
    siteName: '',
    siteTitle: '',
    siteDescription: '',
    logo: ''
  })
  const [success, setSuccess] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => {
    setRefresh(!refresh)
  }
  const [fetchSuccess, setFetchSuccess] = useState(false)

  const {userInfo} = useAppContext()

  
  // get user details
  useEffect(() => {
    const fetchUser = async () => {
      try {

        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        const {data} = await axios.get(`${apiUrl}/user/${userInfo?._id}`, {headers})
        setUserDetails(data)

      } catch (error) {
        console.log('user fetch fail:', error)
      }
    }
    fetchUser();
  },[refresh])

    // console.log(settings)

  return (
    <>
    
    <Helmet>
        <title>Settings | ACEVA Dashboard</title>
      </Helmet>
      <ToastContainer />
      <Layout toggleLoggedInState={toggleLoggedInState}>
        <div className="settings-container p-20">

          <Tabs>

            {/* Tab List */}
            <TabList className='setting-menu-wrap'>
              <Tab className='setting-menu-item'>My Accounts</Tab>
              {/* <Tab className='setting-menu-item'>Billing & Payment</Tab> */}
            </TabList>

            {/* wrapper */}
            <div className="settings-wrapper p-20">
              {/* <form> */}

                  {/* accounts */}
                  <TabPanel className='setting-panel'>
                  {/* <div className='setting-title'>
                    <h2>Genaral</h2>
                    <p>Change the genaral setting of your accounts.</p>
                  </div> */}
                  <div className="form-title">
                    <h2>General settings</h2>
                    <p>Change the general setting of your accounts.</p>
                  </div>
                    <MyAccountTab userDetails={userDetails} toggleRefresh={toggleRefresh}/>
                  </TabPanel>

                  {/* billing and payment */}
                  {/* <TabPanel className='setting-panel'>
                    <div className="form-title">
                      <h2>Billing</h2>
                      <p>Change the general setting of your accounts.</p>
                    </div>
                    <BillingTab />
                  </TabPanel> */}
                
              {/* </form> */}
            </div>
          </Tabs>

          
        </div>
      </Layout>
    </>
  )
}

export default SettingsScreen